import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import TutorialModal from '../components/modals/TutorialModal';
import BodyLayout from '../layout/BodyLayout';
import RightSidebar from '../layout/RightSidebar';
import ThoughtsEditor from '../quest/thought/feed/ThoughtEditor';

const HomePage = () => {
	const [isTutorialOpen, setIsTutorialOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!sessionStorage.getItem('has_visited')) {
			setIsTutorialOpen(true);
			sessionStorage.setItem('has_visited', 'true');
		}
	}, []);

	return (
		<>
			<BodyLayout title="Sage Space">
				<Grid container direction="row" width="100%">
					<ThoughtsEditor />
					<RightSidebar />
				</Grid>
			</BodyLayout>
			<TutorialModal open={isTutorialOpen} onClose={() => setIsTutorialOpen(false)} />
		</>
	);
};

export default HomePage;
