import { Grid, Modal } from '@mui/material';
import React from 'react';
import Tutorial from '../../features/Tutorial/Main';

export interface TutorialModalProps {
  open: boolean;
  onClose: () => void;
}

const TutorialModal: React.FC<TutorialModalProps> = ({ open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        backdropFilter: 'blur(5px)',
        zIndex: 3000,
      }}
    >
      <Grid
        container
        direction="column"
        sx={{
          height: '720px',
          width: '1000px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowX: 'hidden',
          overflowY: 'hidden',
          borderRadius: '16px',
          '&:focus': {
            outline: 'none',
          },
        }}
      >
        <Grid
          container
          item
          xs
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            borderRadius: '16px',
            p: 2,
          }}
        >
          <Tutorial on_close={onClose} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default TutorialModal; 