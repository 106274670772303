import React from "react";
import VaultContent from "../components/VaultContent";
import BodyLayout from "../layout/BodyLayout";

const VaultPage: React.FC = () => {
  return (
    <BodyLayout title="Sage Vault">
      <VaultContent />
    </BodyLayout>
  );
};

export default VaultPage;
