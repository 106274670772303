import { useAuth0 } from '@auth0/auth0-react';
import { LockRounded, LogoutRounded } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Icons } from '../../components/organisms/Icons';
import QuestModal from '../../quest/QuestModal';
import { onLogout } from '../../Util/Helper';

interface HeaderActionsProps {
  title: string;
}

const HeaderActions: React.FC<HeaderActionsProps> = ({ title }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const [animateLock, setAnimateLock] = React.useState(false);
  const [openQuest, setOpenQuest] = React.useState(false);

  const handleLock = () => {
    setAnimateLock(true);
    sessionStorage.removeItem('authenticated_state');
    navigate('/space/gringotts');
  };

  const handleShowQuest = () => {
    setOpenQuest(true);
  };

  return (
    <Grid container item width="320px" justifyContent="flex-end">
      <QuestModal open={openQuest} setOpen={setOpenQuest} />
      
      {/* Insights Button */}
      <Tooltip title="Open Insights">
        <IconButton
          sx={actionButtonStyle}
          onClick={handleShowQuest}
        >
          <Icons.Rocket />
        </IconButton>
      </Tooltip>

      {/* Logout Button */}
      <Tooltip title="Leave Sage Space">
        <IconButton
          sx={actionButtonStyle}
          onMouseEnter={() => setAnimateLock(true)}
          onMouseLeave={() => setAnimateLock(false)}
          onClick={() => onLogout(dispatch, logout)}
        >
          <LogoutRounded />
        </IconButton>
      </Tooltip>

      {/* Lock Button */}
      <Tooltip title="Lock Sage Space">
        <IconButton
          sx={actionButtonStyle}
          onMouseEnter={() => setAnimateLock(true)}
          onMouseLeave={() => setAnimateLock(false)}
          onClick={handleLock}
        >
          <LockRounded />
        </IconButton>
      </Tooltip>
    </Grid>
  );
};

// Shared styles for action buttons
const actionButtonStyle = {
  marginRight: '16px',
  color: '#AEB2BC',
  width: '46px',
  height: '46px'
};

export default HeaderActions; 