import { Grid, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SageType from '../../../typography/SageType';
import {
	CHARCOAL,
	get_text_color,
	get_thought_ratios,
	get_today,
	parse_daily_thoughts
} from '../../../Util/Helper';
import { RootState } from '../../../Util/Redux/Schema';
import { dash_title_typography_styles } from '../../../Util/Styles';
import GeneratePromptButton from './GeneratePromptButton';
import { RANDOM_PROMPTS } from './randomPrompts';
import ThoughtBar, { ThoughtBarHandle } from './ThoughtBar';
import ThoughtList from './ThoughtList';

export const ThoughtsEditor: React.FC = () => {
	const boxRef = useRef<HTMLDivElement | null>(null);
	const all_thoughts = useSelector(
		(state: RootState) => state.thoughts.all_thoughts
	);
	const all_sage_thoughts = useSelector(
		(state: RootState) => state.sageThoughts
	);
	const thoughts = parse_daily_thoughts(all_thoughts);

	// State to store the generated prompt text.
	const [generated_prompt, set_generated_prompt] = useState<string | null>(
		null
	);

	// Create a ref for the ThoughtBar component.
	const thoughtBarRef = useRef<ThoughtBarHandle>(null);

	// Handler to generate the next prompt and focus the ThoughtBar.
	const handleGeneratePrompt = () => {
		set_generated_prompt(
			RANDOM_PROMPTS[Math.floor(Math.random() * RANDOM_PROMPTS.length)]
		);

		// Focus the ThoughtBar when a new prompt is generated.
		thoughtBarRef.current?.focus();
	};

	// Callback to reset the placeholder back to default when a thought is submitted.
	const onThoughtSubmitted = () => {
		set_generated_prompt(null);
	};

	return (
		<Grid
			container
			item
			xs={8}
			sx={(theme) => ({
				backgroundColor: 'white',
				alignItems: 'center',
				justifyContent: 'flex-start',
				alignContent: 'flex-start',
				border: '1px solid #F1F1F1',
				borderRadius: theme.spacing(2),
				padding: theme.spacing(4.5, 0),
				marginRight: theme.spacing(1)
			})}
			direction="column"
			ref={boxRef}
		>
			<Grid
				container
				item
				xs
				direction="column"
				sx={(theme) => ({
					padding: theme.spacing(0, 5)
				})}
			>
				<Grid container item alignItems="center">
					<Grid container item xs>
						<SageType sx={dash_title_typography_styles}>
							{get_today()}
						</SageType>
					</Grid>
					<Grid
						container
						item
						xs
						justifyContent="flex-end"
						alignItems="center"
					>
						<Typography
							sx={{
								fontSize: '14px',
								fontFamily: 'arial',
								color: CHARCOAL
							}}
						>
							{
								get_thought_ratios(
									'Day',
									thoughts,
									all_sage_thoughts
								).joy_ratio
							}{' '}
							joyful | {thoughts.length} thought
							{thoughts.length === 1 ? '' : 's'}
						</Typography>
					</Grid>
				</Grid>

				<Grid
					container
					direction="column"
					justifyContent="center"
					// alignItems="center"
					item
					xs
				>
					<ThoughtList thoughts={thoughts} date_range="Day" mutable />
					<GeneratePromptButton onClick={handleGeneratePrompt} />
					{generated_prompt && (
						<Typography
							style={{
								margin: '8px',
								marginTop: '0px',
								// marginLeft: '8px',
								color: get_text_color(),
								fontSize: '12px',
								fontFamily: 'arial'
							}}
						>
							<b>Prompt:</b> {generated_prompt}
						</Typography>
					)}
					<ThoughtBar
						ref={thoughtBarRef}
						placeholder={"What's on your mind?"}
						on_add_thought_additional_event={onThoughtSubmitted}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ThoughtsEditor;
