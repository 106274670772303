import { Grid } from '@mui/material';
import QuestProgressBar from '../QuestProgressBar';

interface DailyQuestCardProps {
  title: string;
  icon: React.ReactNode;
  value: number;
  max_count: number;
}
const DailyQuestCard: React.FC<DailyQuestCardProps> = ({ title, icon, value, max_count }) => {
  return (
    <Grid container item xs alignItems="center" padding="4px 0" direction="column">
      <Grid container item xs>
        <Grid container item direction="row" alignItems="center" sx={{ height: '100%' }}>
          <Grid container item xs="auto">
            {icon}
          </Grid>
          <Grid
            container
            item
            xs
            direction="column"
            sx={{
              alignItems: 'center',
              borderRadius: '50%',
              paddingRight: '8px',
            }}
          >
            <Grid container item xs alignItems="center" justifyContent="center">
              <Grid container item xs>
                <QuestProgressBar icon={icon} title={title} current={value} total={max_count} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DailyQuestCard;
