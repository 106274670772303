import thunk, { ThunkMiddleware } from "redux-thunk";
import {
  AuthAction,
  ConfigAction,
  PulseOfPlanetAction,
  RootActions,
  SageThoughtsAction,
  StatsAction,
  ThoughtsAction,
  UserAction,
} from "./Action";
import {
  ADD_BREATH_COUNT,
  ADD_GRATITUDE,
  ADD_THOUGHT,
  ADD_THOUGHT_SENTIMENT,
  CREATE_SAGE_THOUGHT,
  FETCH_SENTIMENT_SCORE,
  FETCH_THOUGHT_NOUNS,
  FETCH_USER,
  GET_MONTHLY_GOLDEN_HOUR_STATS,
  GET_THOUGHT_COUNTS,
  GET_THOUGHT_NOUNS,
  GET_WEEKLY_GOLDEN_HOUR_STATS,
  MODIFY_TIMER_DURATION,
  REMOVE_THOUGHT,
  REMOVE_THOUGHT_EMOTIONS,
  SELECT_THOUGHT_EMOTIONS,
  SET_AUTHENTICATED,
  SET_AUTHORIZED,
  SET_AUTHORIZED_STATE,
  SET_BREATH_COUNT,
  SET_CURRENT_MONTH_THOUGHTS,
  SET_GRATITUDES,
  SET_PIN_TOKEN,
  SET_SAGE_THOUGHTS,
  SET_SESSION_TOKEN,
  UPDATE_SAGE_THOUGHT,
} from "./Middleware";
import {
  Auth,
  Config,
  PulseOfPlanet,
  RootState,
  Stats,
  Thoughts,
  User,
  initialState
} from "./Schema";

import { combineReducers } from "@reduxjs/toolkit";
import { produce } from "immer";
import { applyMiddleware } from "redux";

export const configReducer = (
  state: Config = initialState.config,
  action: ConfigAction
) => {
  switch (action.type) {
    case MODIFY_TIMER_DURATION:
      return { ...state, config: { duration: action.payload.duration } };
    default:
      return state;
  }
};

const authReducer = (state: Auth = initialState.auth, action: AuthAction) => {
  switch (action.type) {
    case SET_SESSION_TOKEN:
      return { ...state, sessionToken: action.payload.token };
    case SET_PIN_TOKEN:
      return { ...state, pinToken: action.payload.token };
    case SET_AUTHORIZED:
      return { ...state, isAuthorized: action.payload.isAuthorized };
    case SET_AUTHORIZED_STATE:
      return { ...state, authorized_state: action.payload.state };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };
    default:
      return state;
  }
};

const pulseOfPlanetReducer = (
  state: PulseOfPlanet = initialState.pulseOfPlanet,
  action: PulseOfPlanetAction
) => {
  switch (action.type) {
    case ADD_BREATH_COUNT:
      return {
        ...state,
        breath_count: state.breath_count + action.payload.count,
      };
    case SET_BREATH_COUNT:
      return {
        ...state,
        breath_count: action.payload.count,
      };
    case ADD_GRATITUDE:
      return {
        ...state,
        gratitudes: [...state.gratitudes, action.payload.text],
      };
    case SET_GRATITUDES:
      return {
        ...state,
        gratitudes: action.payload.gratitudes,
      };
    default:
      return state;
  }
};
// const thoughtReducer = produce((draft, action) => {
// 	switch (action.type) {
// 		case CREATE_SAGE_THOUGHT:
// 			const uuid = action.payload.thought_uuid;
// 			return {
// 				...state,
// 				current_month_sage_thoughts: {
// 					...state.current_month_sage_thoughts,
// 					[uuid]: action.payload.sage_thought
// 				}
// 			};
// 		case SET_SAGE_THOUGHTS:
// 			return {
// 				...state,
// 				current_month_sage_thoughts: action.payload.sage_thoughts
// 			};
// 		case UPDATE_SAGE_THOUGHT:
// 			// console.log('Thought id', action.payload.thought_uuid);
// 			// const thought_uuid = action.payload.thought_uuid;
// 			// const update_obj =
// 			// 	action.payload.reframed_thought.user_reframed_thought;
// 			// console.log('Update object', update_obj);
// 			// const thought =
// 			// 	state.current_month_sage_thoughts[thought_uuid] || {};
// 			// console.log('Thought:', thought); // Is this undefined?
// 			// console.log('Update Object:', action.payload.reframed_thought);
// 			// const safeKey = `${thought_uuid}`;
// 			const { thought_uuid, update_obj } = action.payload;
// 			const thought =
// 				draft.current_month_sage_thoughts[thought_uuid] || {};
// 			thought.user_reframed_thought = update_obj.user_reframed_thought;
// 			break;
// 		// return {
// 		// 	...state,
// 		// 	current_month_sage_thoughts: {
// 		// 		...state.current_month_sage_thoughts,
// 		// 		[safeKey]: {
// 		// 			...thought,
// 		// 			user_reframed_thought: update_obj
// 		// 			// ...action.payload.reframed_thought
// 		// 		}
// 		// 	}
// 		// };
// 		case SET_CURRENT_MONTH_THOUGHTS:
// 			draft.all_thoughts = action.payload.thoughts;
// 			break;
// 		// return {
// 		// 	...state,
// 		// 	all_thoughts: action.payload.thoughts
// 		// };
// 		case ADD_THOUGHT:
// 			draft.all_thoughts = [
// 				draft.all_thoughts,
// 				action.payload.thoughts
// 			];
// 			break;
// 		case SELECT_THOUGHT_EMOTIONS:
// 			return {
// 				...state,
// 				all_thoughts: state.all_thoughts.map(
// 					(thought) =>
// 						thought.uuid === action.payload.uuid
// 							? {
// 									...thought,
// 									user_selected_emotions:
// 										action.payload.user_selected_emotions,
// 									sentiment: action.payload.sentiment
// 							  }
// 							: thought
// 				)
// 			};
// 		case REMOVE_THOUGHT_EMOTIONS:
// 			return {
// 				...state,
// 				all_thoughts: state.all_thoughts.map(
// 					(thought) =>
// 						thought.uuid === action.payload.uuid
// 							? {
// 									...thought,
// 									user_selected_emotions: []
// 							  }
// 							: thought
// 				)
// 			};
// 		case UPDATE_THOUGHT:
// 			return {
// 				...state,
// 				all_thoughts: state.all_thoughts.map(
// 					(thought) =>
// 						thought.uuid === action.payload.uuid
// 							? { ...thought, ...action.payload.updatedThought }
// 							: thought
// 				)
// 			};
// 		case ADD_THOUGHT_SENTIMENT:
// 			return {
// 				...state,
// 				all_thoughts: state.all_thoughts.map(
// 					(thought) =>
// 						thought.uuid === null
// 							? {
// 									...thought,
// 									uuid: action.payload.uuid,
// 									sentiment: action.payload.sentiment,
// 									computed_top_emotions:
// 										action.payload.computed_top_emotions
// 							  }
// 							: thought
// 				)
// 			};
// 		case REMOVE_THOUGHT:
// 			return {
// 				...state,
// 				all_thoughts: state.all_thoughts.filter(
// 					(thought) => thought.uuid === null
// 				)
// 			};
// 		default:
// 			return state;
// 	}
// });

const sageThoughtsReducer = (
  state = initialState.sageThoughts,
  action: SageThoughtsAction
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case CREATE_SAGE_THOUGHT:
        const uuid = action.payload.thought_uuid;
        draft[uuid] = action.payload.sage_thought;
        break;
      case SET_SAGE_THOUGHTS:
        Object.assign(draft, action.payload.sage_thoughts);
        break;
      case UPDATE_SAGE_THOUGHT:
        const { thought_uuid, reframed_thought } = action.payload;
        draft[thought_uuid].user_reframed_thought = reframed_thought;
        break;

      default:
        break;
    }
  });
};

const thoughtReducer = (
  state: Thoughts = initialState.thoughts,
  action: ThoughtsAction
) => {
  switch (action.type) {
    case SET_CURRENT_MONTH_THOUGHTS:
      return {
        ...state,
        all_thoughts: action.payload.thoughts,
      };
    case ADD_THOUGHT:
      return {
        ...state,
        all_thoughts: [...state.all_thoughts, action.payload.thought],
      };
    case SELECT_THOUGHT_EMOTIONS:
      return {
        ...state,
        all_thoughts: state.all_thoughts.map((thought) =>
          thought.uuid === action.payload.uuid
            ? {
                ...thought,
                user_selected_emotions: action.payload.user_selected_emotions,
                sentiment: action.payload.sentiment,
              }
            : thought
        ),
      };
    case REMOVE_THOUGHT_EMOTIONS:
      return {
        ...state,
        all_thoughts: state.all_thoughts.map((thought) =>
          thought.uuid === action.payload.uuid
            ? {
                ...thought,
                user_selected_emotions: [],
              }
            : thought
        ),
      };
    // case UPDATE_THOUGHT:
    // 	return {
    // 		...state,
    // 		all_thoughts: state.all_thoughts.map(
    // 			(thought) =>
    // 				thought.uuid === action.payload.uuid
    // 					? { ...thought, ...action.payload.updatedThought }
    // 					: thought
    // 		)
    // 	};
    case ADD_THOUGHT_SENTIMENT:
      return {
        ...state,
        all_thoughts: state.all_thoughts.map((thought) =>
          thought.uuid === null
            ? {
                ...thought,
                uuid: action.payload.uuid,
                sentiment: action.payload.sentiment,
                computed_top_emotions: action.payload.computed_top_emotions,
              }
            : thought
        ),
      };
    case REMOVE_THOUGHT:
      return {
        ...state,
        all_thoughts: state.all_thoughts.filter(
          (thought) => thought.uuid === null
        ),
      };
    default:
      return state;
  }
};

const userReducer = (state: User = initialState.user, action: UserAction) => {
  switch (action.type) {
    case FETCH_USER:
      return action.payload.user;
    default:
      return state;
  }
};

const statReducer = (
  state: Stats = initialState.stats,
  action: StatsAction
) => {
  switch (action.type) {
    case FETCH_THOUGHT_NOUNS:
      return {
        ...state,
        nouns: action.payload.nouns,
      };
    case FETCH_SENTIMENT_SCORE:
      return {
        ...state,
        sentimentScore: action.payload.score,
      };
    case GET_WEEKLY_GOLDEN_HOUR_STATS:
      return {
        ...state,
        weeklyGoldenHour: [...action.payload.weeklyGoldenHour],
        weeklyFetched: true,
      };
    case GET_MONTHLY_GOLDEN_HOUR_STATS:
      return {
        ...state,
        monthlyGoldenHour: [...action.payload.monthlyGoldenHour],
        monthlyFetched: true,
      };
    case GET_THOUGHT_NOUNS:
      if (action.payload.type === "Week") {
        return {
          ...state,
          weeklyThoughtNouns: {
            positive: action.payload.positiveThoughtNouns,
            neutral: action.payload.neutralThoughtNouns,
            negative: action.payload.negativeThoughtNouns,
          },
        };
      } else if (action.payload.type === "Month") {
        return {
          ...state,
          monthlyThoughtNouns: {
            positive: action.payload.positiveThoughtNouns,
            neutral: action.payload.neutralThoughtNouns,
            negative: action.payload.negativeThoughtNouns,
          },
        };
      } else {
        return {
          ...state,
          allTimeThoughtNouns: {
            positive: action.payload.positiveThoughtNouns,
            neutral: action.payload.neutralThoughtNouns,
            negative: action.payload.negativeThoughtNouns,
          },
        };
      }
    case GET_THOUGHT_COUNTS:
      return {
        ...state,
        allTimeThoughtCounts: {
          positive: action.payload.numPositiveThoughts,
          neutral: action.payload.numNeutralThoughts,
          negative: action.payload.numNegativeThoughts,
        },
      };
    default:
      return state;
  }
};

// Use RootState and ListAction to create the Store
const appReducer = combineReducers({
  // priorities: prioritiesReducer,
  config: configReducer,
  pulseOfPlanet: pulseOfPlanetReducer,
  auth: authReducer,
  thoughts: thoughtReducer,
  stats: statReducer,
  user: userReducer,
  sageThoughts: sageThoughtsReducer,
});
export const rootReducer = (state: any, action: any) => {
  // localStorage.clear();
  window.addEventListener("beforeunload", () => {
    // Check if it's the first time the page is loaded
    if (!localStorage.getItem("appInitialized")) {
      // Clear the localStorage
      localStorage.clear();
      // Set a flag to indicate that the app has been initialized
      localStorage.setItem("appInitialized", "true");
    }
  });

  applyMiddleware(thunk as ThunkMiddleware<RootState, RootActions>);

  return appReducer(state, action);
};
