import { ZoomOutMapRounded } from '@mui/icons-material';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	DARK_GRAY,
	get_sky_blue,
	get_text_color,
	parse_daily_gratitudes
} from '../../Util/Helper';
import { RootState } from '../../Util/Redux/Schema';
import BaseCard from '../../components/molecules/BaseCard';
import CardHeader from '../../components/molecules/CardHeader';
import TitleWithIcon from '../../components/molecules/TitleWithIcon';
import { Icons } from '../../components/organisms/Icons';
import BreathModal from './BreathModal';
import GratitudeInput from './GratitudeInput';

export default function MindfulMoments() {
	const breath_count = useSelector(
		(state: RootState) => state.pulseOfPlanet.breath_count
	);
	const gratitude_count = parse_daily_gratitudes(
		useSelector((state: RootState) => state.thoughts.all_thoughts)
	).length;
	const [open_breath, set_open_breath] = useState(false);
	const [open_modal, set_open_modal] = useState(false);
	const toggle_breath = () => set_open_breath(!open_breath);
	const handle_close_modal = () => set_open_modal(false);
	const handle_close_breath = () => {
		set_open_breath(false);
		set_open_modal(false);
	};

	const [open_gratitude, set_open_gratitude] = useState(false);
	const toggle_gratitude = () => set_open_gratitude(!open_gratitude);
	const toggle_all = () => {
		if (open_gratitude || open_breath) {
			set_open_gratitude(false);
			set_open_breath(false);
		} else {
			set_open_gratitude(true);
			// set_open_breath(true);
		}
	};
	const [breathing_page, set_breathing_page] = useState(0);

	return (
		<BaseCard sx={{ border: '2px solid ' + get_sky_blue() }}>
			<Tooltip
				title={
					open_gratitude || open_breath
						? 'Hide Mindful Space'
						: 'Expand Mindful Space'
				}
			>
				<CardHeader
					onClick={() => toggle_all()}
					title="Mindful Space"
					rightComponent={
						<Grid
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'flex-end',
								width: '100%'
							}}
						>
							<Tooltip
								title={
									open_gratitude
										? 'Close Gratitude'
										: 'Open Gratitude'
								}
							>
								<span
									onClick={(e) => {
										e.stopPropagation();
										toggle_gratitude();
									}}
									style={{
										cursor: 'pointer',
										marginRight: '8px',
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<Typography
										sx={{
											fontSize: '14px',
											fontFamily: 'arial',
											marginRight: '4px',
											color: open_gratitude
												? get_text_color()
												: DARK_GRAY
										}}
									>
										{gratitude_count}
									</Typography>
									<Icons.Heart
										style={{
											fontSize: '18px',
											color: open_gratitude
												? undefined
												: DARK_GRAY
										}}
									/>
								</span>
							</Tooltip>
							<Tooltip
								title={
									open_breath ? 'Close Breath' : 'Open Breath'
								}
							>
								<span
									onClick={(e) => {
										e.stopPropagation();
										toggle_breath();
									}}
									style={{
										cursor: 'pointer',
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<Typography
										sx={{
											fontSize: '14px',
											fontFamily: 'arial',
											marginRight: '4px',
											color: get_text_color()
										}}
									>
										{breath_count}
									</Typography>
									<Icons.Breathe
										style={{
											fontSize: '18px',
											color: undefined
										}}
									/>
								</span>
							</Tooltip>
						</Grid>
					}
				/>
			</Tooltip>

			<Grid
				container
				item
				xs
				direction="column"
				marginTop="24px"
				style={{ display: open_gratitude ? 'block' : 'none' }}
			>
				<GratitudeInput />
			</Grid>

			<Grid
				container
				item
				xs
				direction="column"
				marginTop="24px"
				style={{ display: open_breath ? 'block' : 'none' }}
			>
				<Grid container item direction="row" alignItems="center">
					<Grid container item xs={6}>
						<TitleWithIcon
							title="Breathe"
							icon={
								<Icons.Breathe
									style={{
										fontSize: '20px',
										color: open_breath
											? undefined
											: DARK_GRAY
									}}
								/>
							}
						/>
					</Grid>
					<Tooltip title="Open Breathing Popup">
						<Grid container item xs justifyContent="flex-end">
							<IconButton
								onClick={() => set_open_modal(true)}
								sx={{ color: DARK_GRAY }}
							>
								<ZoomOutMapRounded
									style={{ fontSize: '16px' }}
								/>
							</IconButton>
						</Grid>
					</Tooltip>{' '}
				</Grid>
				{/* <Grid
					container
					item
					// padding="24px 0"
					paddingTop="0px"
					paddingBottom="16px"
					direction="column"
				>
					<BreathCircle
						maxCount={5}
						size="small"
						countDirection="up"
						showCount={true}
					/>
				</Grid> */}
			</Grid>
			<BreathModal open={open_breath} onClose={handle_close_breath} />
		</BaseCard>
	);
}
