import CheckIcon from '@mui/icons-material/Check';
import { Grid } from '@mui/material';
import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';
import SageType from '../../../typography/SageType';
import { get_sentiment_color, LIGHT_GREEN, LIGHT_RED, LIGHT_YELLOW } from '../../../Util/Helper';

const RAINBOW_COLORS = [LIGHT_RED, LIGHT_GREEN, LIGHT_YELLOW];

const ReleaseThought = (props: { onClose: () => void }) => {
  const circleRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const checkRef = useRef<HTMLDivElement>(null);
  const [isReleased, setIsReleased] = useState(false);

  useEffect(() => {
    if (isReleased && circleRef.current) {
      const rect = circleRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
        const xs = x2 - x1;
        const ys = y2 - y1;
        return Math.sqrt(xs * xs + ys * ys);
      };

      // Calculate the distance to the furthest corner
      const distances = [
        getDistance(centerX, centerY, 0, 0),
        getDistance(centerX, centerY, window.innerWidth, 0),
        getDistance(centerX, centerY, 0, window.innerHeight),
        getDistance(centerX, centerY, window.innerWidth, window.innerHeight),
      ];
      const maxDistance = Math.max(...distances);
      const scale = (maxDistance * 2) / rect.width;

      gsap.set(circleRef.current, {
        transformOrigin: '50% 50%',
        borderRadius: '50%',
        width: rect.width,
        height: rect.width,
      });

      // Create timeline for the animation
      const tl = gsap.timeline({
        onComplete: () => {
          if (textRef.current && checkRef.current) {
            // Fade out text
            gsap.to(textRef.current, {
              opacity: 0,
              duration: 0.3,
              onComplete: () => {
                gsap.set(textRef.current, { display: 'none' });

                // Position and show checkmark
                gsap.set(checkRef.current, {
                  display: 'flex',
                  scale: 0,
                  opacity: 0,
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  xPercent: -50,
                  yPercent: -50,
                });

                // Animate checkmark
                gsap.to(checkRef.current, {
                  scale: 0.5,
                  opacity: 1,
                  duration: 0.5,
                  ease: 'back.out(1.7)',
                  onComplete: () => {
                    setTimeout(() => {
                      props.onClose();
                    }, 500);
                  },
                });
              },
            });
          }
        },
      });

      // Add color transitions during expansion
      RAINBOW_COLORS.forEach((color, index) => {
        tl.to(
          circleRef.current,
          {
            backgroundColor: color,
            duration: 0.25, // Duration for each color
            ease: 'none',
          },
          0, // Start at the beginning of the timeline
        );
      });

      // Main expansion animation
      tl.to(
        circleRef.current,
        {
          scale: scale,
          borderRadius: '50%',
          duration: 1.5,
          ease: 'power2.inOut',
        },
        0, // Start at the beginning of the timeline
      );

      // Keep text size stable
      if (textRef.current) {
        gsap.to(textRef.current, {
          scale: 1 / scale,
          duration: 1.5,
          ease: 'power2.inOut',
        });
      }
    }
  }, [isReleased, props.onClose]);

  const handleRelease = () => {
    if (!isReleased) {
      setIsReleased(true);
    }
  };

  return (
    <Grid
      container
      item
      xs
      sx={{
        position: 'relative',
        marginTop: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        ref={circleRef}
        onClick={handleRelease}
        sx={{
          backgroundColor: LIGHT_RED,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          height: '160px',
          width: '160px',
          cursor: 'pointer',
          zIndex: isReleased ? 10000 : 2,
          transition: 'background-color 0.3s ease',
        }}
      >
        <div ref={textRef} style={{ transformOrigin: 'center' }}>
          <SageType
            style={{
              fontSize: '24px',
              color: get_sentiment_color('harsh').color,
              whiteSpace: 'nowrap',
            }}
          >
            release
          </SageType>
        </div>
        <div
          ref={checkRef}
          style={{
            display: 'none',
            transformOrigin: 'center',
            color: get_sentiment_color('sage').color,
            width: '32px',
            height: '32px',
            position: 'absolute',
          }}
        >
          <CheckIcon
            sx={{
              fontSize: 32,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default ReleaseThought;
