import {
	Grid,
	Tooltip,
	TooltipProps,
	Typography,
	tooltipClasses
} from '@mui/material';
import { get_sentiment_color, parse_daily_gratitudes } from '../../Util/Helper';

import { FavoriteRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../Util/Redux/Schema';
import TitleWithIcon from '../../components/molecules/TitleWithIcon';
import { Icons } from '../../components/organisms/Icons';
import GratitudeBar from './GratitudeBar';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#2f4150',
		width: '240px',
		// height: '240px',
		color: '#fff',
		fontSize: '14px',
		padding: '16px',
		// display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		textAlign: 'left'
	}
});
const TooltipContent = (props: { gratitudes: string[] }) => (
	<Grid container item xs direction="column" height="100%">
		<Grid container item xs={1}>
			<Typography
				sx={{
					fontFamily: 'arial',
					color: 'white',
					fontSize: '16px',
					marginBottom: '8px'
				}}
			>
				I love...
			</Typography>
		</Grid>
		<Grid container item xs="auto" direction="column">
			{props.gratitudes.map((gratitude) => (
				<Grid container item xs alignItems="center">
					<Grid container item marginBottom="8px">
						<FavoriteRounded
							sx={{
								color: get_sentiment_color('harsh').color,
								width: '16px',
								height: '16px',
								marginRight: '8px'
							}}
						/>
						<Grid container item xs>
							<Typography
								sx={{
									color: 'white',
									fontSize: '12px',
									fontFamily: 'arial'
								}}
							>
								{gratitude}
							</Typography>
						</Grid>
					</Grid>{' '}
				</Grid>
			))}
		</Grid>
	</Grid>
);

export default function GratitudeInput(props: { intention?: boolean }) {
	const gratitudes = parse_daily_gratitudes(
		useSelector((state: RootState) => state.thoughts.all_thoughts)
	);
	return (
		<Grid container item xs direction="column">
			<CustomTooltip
				title={
					<TooltipContent
						gratitudes={
							gratitudes.length === 0
								? ['Example: my family']
								: gratitudes
						}
					/>
				}
				placement="left"
			>
				<span>
					<TitleWithIcon
						title="Gratitudes"
						icon={<Icons.Heart style={{ fontSize: 14 }} />}
					/>
				</span>
			</CustomTooltip>
			<GratitudeBar
				placeholder={
					props.intention ? 'Today I will...' : 'I am grateful for...'
				}
			/>
		</Grid>
	);
}
