import { Grid, Modal, SxProps } from '@mui/material';
import React from 'react';

export interface Decoration {
	icon: React.ReactNode;
	sx: SxProps;
}

interface DecoratedModalProps {
	open: boolean;
	onClose: () => void;
	backgroundColor: string;
	width: string;
	height: string;
	borderRadius?: string;
	children: React.ReactNode;
}

const DecoratedModal: React.FC<DecoratedModalProps> = ({
	open,
	onClose,
	backgroundColor,
	width,
	height,
	borderRadius = '16px',
	children
}) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={{ backdropFilter: 'blur(20px)' }}
		>
			<Grid
				container
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					backgroundColor,
					height,
					width,
					borderRadius,
					overflow: 'hidden',
					'&:focus': {
						outline: 'none'
					}
				}}
			>
				{children}
			</Grid>
		</Modal>
	);
};

export default DecoratedModal;
