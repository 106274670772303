import { Grid, Typography } from '@mui/material';
import { CHARCOAL, SubmitButton } from '../../Util/Helper';
import { Icons } from '../../components/organisms/Icons';

export default function Introduction(props: {
  set_page: (page: number) => void;
  on_close: () => void;
}) {
  return (
    <Grid container item xs sx={{ overflow: 'hidden', position: 'relative', borderRadius: '24px' }}>
      {/* <Grid container item sx={{ position: 'absolute', top: '-120px', left: '780px' }}>
        <Icons.PurpleDaisy height="300px" transform="rotate(180deg)" />
      </Grid> */}
      {/* <Grid container item sx={{ position: 'absolute', top: '30px', left: '840px' }}>
        <Icons.Logo height="80px" />
      </Grid> */}
      <Grid container item xs position="absolute" top="0" left="0">
        <Icons.PurpleSquiglyGreenCircle height="200px" />
      </Grid>
      <Grid container item xs position="absolute" top="-120px" left="800px">
        <Icons.YellowDaisy height="300px" />
      </Grid>
      <Grid
        container
        item
        xs
        padding="100px"
        paddingLeft="140px"
        paddingBottom="24px"
        paddingTop="60px"
        direction="column"
      >
        <Grid container item xs={2} direction="column" alignItems="flex-start">
          <Icons.WelcomeToSage height="32px" />
          <Typography
            sx={{
              fontSize: '18px',
              color: CHARCOAL,
              fontFamily: 'arial',
              fontWeight: 500,
            }}
          >
            Your Private Space for Self-Discovery 🌱
          </Typography>
        </Grid>
        {/* <Icons.Introduction height="500px" /> */}
        <Grid container item xs justifyContent="flex-start" alignItems="flex-start">
          <Grid container item xs={8}>
            <Grid container item xs direction="column">
              <Typography
                sx={{
                  fontSize: '16px',
                  color: CHARCOAL,
                  fontFamily: 'arial',
                  fontWeight: 500,
                }}
              >
                <span>
                  Sage is your <b>self-discovery companion,</b> designed to guide you on your
                  journey of <b>self-awareness.</b> We hope you use this reflective space as an
                  outlet for your thoughts and emotions.
                  <br />
                  <br />
                  As you share, Sage learns and grows with you, turning your thoughts into
                  meaningful weekly insights that deepen your connection with your mind, body, and
                  spirit.
                </span>
                <br />
                <br />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          marginTop="24px"
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Icons.OurPrivacyPromise margin="0 0 16px 0" />
          {[
            <span>
              Your entries are automatically deleted after <b>30 days</b>
            </span>,
            <span>
              Your space is completely <b>private</b> to you
            </span>,
            <span>
              We only keep our understanding of you, surfaced in your{' '}
              <b>personalized insights report.</b>
            </span>,
          ].map((promise) => (
            <Grid container item xs alignItems="center">
              <Icons.Star style={{ marginRight: '8px' }} />
              {promise}
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={1.5} paddingTop="32px" marginTop="24px">
          <>
            <Grid container item xs={10}>
              <SubmitButton fullWidth text="Get Started" onClick={() => props.set_page(0)} />
            </Grid>
            <Grid container item xs>
              <SubmitButton fullWidth text="Skip" secondary onClick={() => props.on_close()} />
            </Grid>
          </>
        </Grid>
      </Grid>
    </Grid>
  );
}
