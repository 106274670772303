import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import DecoratedModal, { Decoration } from '../../components/molecules/DecoratedModal';
import { Icons } from '../../components/organisms/Icons';
import BreathCircle from '../../features/MindfulBreathing';
import DecorationOverlay from '../../features/MindfulBreathing/PageOverlay';
import { get_light_blue, LIGHT_GREEN, SubmitButton } from '../../Util/Helper';
import { post_breath_count } from '../../Util/Redux/AsyncMiddleware';
import { AppDispatch } from '../../Util/Redux/Schema';
import BreathDurationConfig from './BreathDurationConfig';
import SessionHeader from './components/SessionHeader';
import SessionInstructions from './components/SessionInstructions';

const decorations: Decoration[] = [
  {
    icon: <Icons.PurpleSquiglyGreenCircle height="320px" />,
    sx: {
      position: 'absolute',
      top: '8px',
      left: '8px',
    },
  },
  // {
  // 	icon: <Icons.PurpleDaisy height="210px" />,
  // 	sx: {
  // 		position: 'absolute',
  // 		top: '-88px',
  // 		right: '-92px'
  // 	}
  // },
  {
    icon: <Icons.GreenSquiglyYellowCircle height="120px" />,
    sx: {
      position: 'absolute',
      bottom: '8px',
      right: '8px',
    },
  },
];

export default function BreathModal(props: { open: boolean; onClose: () => void }) {
  // started = false if not clicked, started = true if clicked, started = null if finished
  const [started, set_started] = useState<null | boolean>(false);
  const [selected_duration, set_selected_duration] = useState(1);
  const total_rounds = selected_duration === 1 ? 5 : selected_duration === 3 ? 15 : 25;
  const title = `${selected_duration} minute${' '}
	${selected_duration === 1 ? 'break' : selected_duration === 3 ? 'pause' : 'reset'}`;
  const dispatch = useDispatch<AppDispatch>();
  const on_close = () => {
    set_started(false);
    dispatch(post_breath_count(selected_duration));
    props.onClose();
  };

  return (
    <DecoratedModal
      open={props.open}
      width="1000px"
      onClose={started === false ? props.onClose : undefined}
      height="640px"
      backgroundColor={started !== true ? LIGHT_GREEN : get_light_blue()}
    >
      <DecorationOverlay decorations={decorations} opacity={1} />
      <Grid container item width="100%" height="100%" justifyContent="center" alignItems="center">
        <Grid container item xs direction="column" alignItems="center">
          <SessionHeader title={started === null ? 'Self care is so important' : title} />
          {
            <SessionInstructions
              is_started={started}
              duration={selected_duration}
              total_rounds={total_rounds}
            />
          }
          {started === false && (
            <BreathDurationConfig
              set_started={() => set_started(true)}
              set_selected_duration={set_selected_duration}
              selected_duration={selected_duration}
            />
          )}
          {started === true && (
            <BreathCircle
              size="large"
              total_rounds={total_rounds}
              onClose={() => set_started(null)}
            />
          )}
          {started === false && (
            // <Tooltip title="This will run for the full duration">
            <>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontFamily: 'arial',

                  color: '#858580',
                  cursor: 'default',
                  textAlign: 'center',
                  marginTop: '48px',
                }}
              >
                {' '}
                You will not be able to pause or exit early once you start. <br />
                <b>Settle in, relax, and enjoy the full experience—your mind will thank you! </b>
              </Typography>
              <span>
                <SubmitButton text="Start" onClick={() => set_started(true)} />
              </span>
            </>
            // </Tooltip>
          )}
          {started === null && <SubmitButton text="Close" onClick={on_close} />}
        </Grid>
      </Grid>
    </DecoratedModal>
  );
}
