import { Grid } from '@mui/material';
import React from 'react';

interface BaseCardProps {
  children: React.ReactNode;
  sx?: object;
}

const BaseCard: React.FC<BaseCardProps> = ({ children, sx }) => {
  return (
    <Grid
      container
      direction="column"
      borderRadius="16px"
      sx={{
        backgroundColor: 'white',
        // border: '1px solid #F3F2F3',
        p: 2,
        ...sx
      }}
    >
      {children}
    </Grid>
  );
};

export default BaseCard;
