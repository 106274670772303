import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface BodyLayoutProps {
  title: string;
  children: ReactNode;
}

const BodyLayout: React.FC<BodyLayoutProps> = ({ title, children }) => {
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      width="1024px"
      height="768px"
      maxHeight="100%"
    >
      {/* <Grid container item xs={1}>
        <SageType fontSize="24px">
        {title}
        </SageType> 
			</Grid> */}

      <Grid container item xs width="100%">
        
        <Grid container item xs justifyContent="center" >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BodyLayout; 