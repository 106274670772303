import { Grid, TextareaAutosize, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import {
	getTimestamp,
	get_cta_color,
	get_light_gray
} from '../../../Util/Helper';

import { useDispatch } from 'react-redux';
import { post_thought } from '../../../Util/Redux/AsyncMiddleware';
import { addThought } from '../../../Util/Redux/Middleware';
import { AppDispatch } from '../../../Util/Redux/Schema';

const ThoughtBar = (props: {
	placeholder?: string;
	on_add_thought_additional_event?: () => void;
}) => {
	const [message, setMessage] = useState(
		localStorage.getItem('chatbarMessage') || ''
	);
	const dispatch = useDispatch<AppDispatch>();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length <= 1024) {
			setMessage(event.target.value);
			localStorage.setItem('chatbarMessage', event.target.value);
		}
	};
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault(); // Prevent the default Enter behavior (line break)
			// if (props.default_text) {}
			if (message.length > 0 && message.length <= 1024) {
				dispatch(
					addThought({
						created_at: getTimestamp(),
						sentiment: 'unknown',
						thought: message,
						computed_top_emotions: [],
						uuid: null,
						user_selected_emotions: [],
						reframed: '',
						tags: []
					})
				);
				dispatch(post_thought(message, []));
				if (props.on_add_thought_additional_event) {
					props.on_add_thought_additional_event();
				}

				setMessage('');
				localStorage.removeItem('chatbarMessage');
			}
		} else if (event.key === 'Enter' && event.shiftKey) {
			event.preventDefault(); // Prevent the default Enter behavior (line break)
			setMessage(message + '\n'); // Add a newline character to the message
			localStorage.setItem('chatbarMessage', message + '\n');
		}
	};
	const textareaRef = useRef();

	return (
		<Grid
			container
			item
			width="100%"
			alignItems="center"
			direction="column"
		>
			{' '}
			<Grid
				container
				item
				xs
				// justifyContent="space-between"
				width="100%"
				alignItems="center"
				sx={{
					backgroundColor: get_light_gray(),
					// border: "2px solid red"
					borderRadius: '24px',
					padding: '8px 16px'
					// paddingBottom: 0
				}}
			>
				<TextareaAutosize
					data-hj-suppress
					ref={textareaRef}
					placeholder={props.placeholder || "What's on your mind?"}
					value={message}
					maxRows={10}
					style={{
						fontFamily: 'arial',
						color: '#0A090B',
						fontWeight: 450,
						backgroundColor: 'transparent',
						fontSize: '14px',
						lineHeight: '20px',
						letterSpacing: '-0.05px',
						width: '100%',
						border: 'none',
						outline: 'none',
						resize: 'none',
						minHeight: '32px',
						paddingTop: '12px',
						paddingBottom: '8px'
					}}
					onChange={handleChange}
					onKeyPress={handleKeyPress}
					autoFocus
				/>
			</Grid>
			{message.length >= 400 && (
				<Grid
					container
					item
					sx={{
						justifyContent: 'flex-end',
						height: '12px',
						marginTop: '8px'
					}}
				>
					<Typography
						sx={{
							color: get_cta_color(),
							fontSize: '12px',
							fontFamily: 'arial'
						}}
					>
						{message.length} / 1024
					</Typography>
					{/* )} */}
				</Grid>
			)}
		</Grid>
	);
};

export default ThoughtBar;
