import { Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import MorangaBold from '../assets/font/Moranga-Bold.otf';
import { getDarkGreen } from '../Util/Helper';

// Create a styled Typography with an @font-face declaration
const StyledTypography = styled(Typography)(({ theme }) => ({
  // Define the custom font face
  '@font-face': [
    {
      fontFamily: 'MorangaBold',
      src: `url(${MorangaBold}) format('opentype')`,
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
  ],
  fontFamily: "'MorangaBold', sans-serif",
  color: getDarkGreen()
}));

// Create the SageType component that wraps the StyledTypography
const SageType: React.FC<TypographyProps> = (props) => {
  return <StyledTypography {...props} />;
};

export default SageType; 