import { Typography } from '@mui/material';
import { get_text_color } from '../../../Util/Helper';

interface SessionInstructionsProps {
	duration: number;
	total_rounds: number;
	is_started: null | boolean;
}

const INSTRUCTIONS = {
	active: [
		'I will focus on my breath.',
		'I will scan my body for tension.',
		'I will send love to my heart.'
	],
	setup: [
		['Rest your mind: ', 'focus on your breath.'],
		['Relax your body: ', 'scan head to toe for any tension.'],
		['Raise your spirit: ', 'send love to your heart.']
	]
} as const;

function getCompletionText(duration: number, total_rounds: number): string {
	const minuteText = `${duration} minute${duration > 1 ? 's' : ''}`;
	const breathText = `${total_rounds} mindful breath${
		(total_rounds || 0) > 1 ? 's' : ''
	}`;
	return `congratulations on taking ${minuteText} out of today to take ${breathText}`;
}

export default function SessionInstructions({
	duration,
	is_started,
	total_rounds
}: SessionInstructionsProps) {
	const index = (duration - 1) / 2;

	const get_text = () => {
		if (is_started === null) {
			return getCompletionText(duration, total_rounds);
		}

		if (is_started) {
			return INSTRUCTIONS.active[index];
		}

		const [prefix, instruction] = INSTRUCTIONS.setup[index];
		return (
			<>
				{prefix}
				<span style={{ fontWeight: '500' }}>{instruction}</span>
			</>
		);
	};

	return (
		<Typography
			sx={{
				fontSize: '20px',
				fontFamily: 'arial',
				color: get_text_color(),
				fontWeight: is_started !== false ? 'normal' : 'bold',
				fontStyle: is_started ? 'italic' : 'normal',
				marginBottom: '32px'
			}}
		>
			{get_text()}
		</Typography>
	);
}
