import { Grid } from '@mui/material';
import React from 'react';
import { headerHeight } from '../../Util/Config';
import Header from '../../layout/Header/Header';

type MainLayoutProps = {
  page: string;
  set_page: (page: string) => void;
  children: React.ReactNode;
};

const MainLayout: React.FC<MainLayoutProps> = ({ page, set_page, children }) => {
  return (
    <Grid
      container
      item
      xs
      direction="row"
      width="100vw"
      height="100vh"
      sx={{
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      <Grid container item xs direction="column">
        <Header page={page} set_page={set_page} />

        <Grid
          container
          item
          xs
          justifyContent="center"
          alignItems="flex-start"
          marginTop={headerHeight}
          height="100%"
          width="100%" // `calc(100vh - ${headerHeight}px)`,
          style={{
            overflowY: 'hidden',
            backgroundImage:
              'linear-gradient(145deg, rgba(255, 244, 225, 0.88), rgba(217, 240, 231, 0.88), rgba(255, 227, 227, 0.88))',
          }}
        >
          {/* <Grid
						container
						item
						xs
						position="absolute"
						bottom="0"
						left="0"
					>
						<img
							src={bottom_left_shape}
							style={{ width: '300px' }}
						/>
					</Grid> */}
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainLayout;
