import { Grid } from '@mui/material';
import * as React from 'react';
import { Icons } from '../../components/organisms/Icons';
import SageType from '../../typography/SageType';
import { headerHeight } from '../../Util/Config';
import { getDarkGreen } from '../../Util/Helper';
import HeaderActions from './HeaderActions';

interface HeaderProps {
	page: string;
	set_page: (page: string) => void;
}

// Logo section with dropdown
const LogoSection: React.FC<HeaderProps> = ({ page, set_page }) => {
	return (
		<Grid
			container
			item
			xs="auto"
			alignItems="center"
			justifyContent="center"
			onClick={() => set_page(page === 'space' ? 'vault' : 'space')}
			zIndex={5000}
			sx={{
				'&:hover': { backgroundColor: '#F1F1F1' },
				cursor: 'pointer',
				padding: '8px 16px',
				borderRadius: '24px'
			}}
		>
			<SageType sx={{ fontSize: '28px' }}>
				{page === 'space' ? 'sage space' : 'sage vault'}
			</SageType>
			<Icons.Dropdown
				style={{
					fontSize: 32,
					color: getDarkGreen(),
					marginLeft: page === 'space' ? '16px' : '22px'
				}}
				sx={{
					transform: page === 'vault' ? 'rotate(180deg)' : undefined
				}}
			/>
		</Grid>
	);
};

// Main Header component
const Header: React.FC<HeaderProps> = ({ page, set_page }) => {
	const title = page === 'Home' ? 'homepage' : page.toLowerCase();

	return (
		<Grid
			container
			item
			xs
			alignItems="center"
			justifyContent="center"
			sx={{
				position: 'fixed',
				height: headerHeight,
				top: 0,
				border: '1px solid #F1F1F1',
				backgroundColor: 'white',
				zIndex: 1000
			}}
		>
			{/* Logo Section */}
			<LogoSection page={page} set_page={set_page} />

			{/* Actions Section */}
			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				position="absolute"
			>
				<HeaderActions title={title} />
			</Grid>
		</Grid>
	);
};

export default Header;
