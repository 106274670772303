import { Grid } from '@mui/material';
import { Decoration } from '../../components/molecules/DecoratedModal';

export default function DecorationOverlay({
	decorations,
	opacity
}: {
	decorations: Decoration[];
	opacity: number;
}) {
	return (
		<Grid
			container
			item
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				height: '100%',
				width: '100%',
				zIndex: 0,
				pointerEvents: 'none'
			}}
			justifyContent="center"
			alignItems="center"
		>
			{decorations?.map((dec, index) => (
				<Grid
					key={index}
					item
					sx={{
						position: 'absolute',
						...dec.sx,
						opacity: opacity,
						pointerEvents: 'none'
					}}
				>
					{dec.icon}
				</Grid>
			))}
		</Grid>
	);
}
