import { Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Filters from "../quest/thought/feed/ThoughtFilters";
import Entries from "../quest/thought/feed/ThoughtList";
import Search from "../quest/thought/feed/ThoughtSearchBar";
import { getMonth } from "../Util/Helper";
import { get_thought_entries, postSearch } from "../Util/Redux/AsyncMiddleware";
import { RootState, Thought } from "../Util/Redux/Schema";

const VaultContent: React.FC = () => {
  const [descending, setDescending] = useState(false);
  const [thoughtEntries, setThoughtEntries] = useState<Thought[]>([]);
  const [monthView, setMonthView] = useState(getMonth());
  const [sentimentIdx, setSentimentIdx] = useState(0);
  const reduxThoughts = useSelector((state: RootState) => state.thoughts.all_thoughts);
  const [monthlyThoughts, setMonthlyThoughts] = useState<Thought[]>(reduxThoughts);

  const onChangeDescending = () => {
    setThoughtEntries([...thoughtEntries].reverse());
    setDescending(!descending);
  };

  const onChangeSentimentIdx = () => {
    updateFilterBySentiment((sentimentIdx + 1) % 4, monthlyThoughts);
  };

  const updateFilterBySentiment = (sentimentIndex: number, entries: Thought[]) => {
    if (sentimentIndex !== sentimentIdx) {
      setSentimentIdx(sentimentIndex);
    }
    let entriesToShow: Thought[] = [];
    if (sentimentIndex === 0) {
      entriesToShow = entries;
    } else if (sentimentIndex === 1) {
      entriesToShow = entries.filter(
        (obj) =>
          obj.sentiment.toLowerCase() === "positive" &&
          obj.user_selected_emotions.length > 0
      );
    } else if (sentimentIndex === 2) {
      entriesToShow = entries.filter(
        (obj) =>
          obj.sentiment.toLowerCase() === "negative" &&
          obj.user_selected_emotions.length > 0
      );
    } else {
      entriesToShow = entries.filter((obj) => obj.user_selected_emotions.length === 0);
    }
    setEntries(entriesToShow);
  };

  const setEntries = (thoughts: Thought[]) => {
    descending ? setThoughtEntries([...thoughts].reverse()) : setThoughtEntries(thoughts);
  };

  const fetchData = async (month: string) => {
    const entries = await get_thought_entries(month);
    if (entries === undefined) {
      setMonthlyThoughts([]);
    } else {
      setMonthlyThoughts(entries);
      updateFilterBySentiment(sentimentIdx, entries);
    }
  };

  useEffect(() => {
    if (monthlyThoughts.length === 0) {
      fetchData(monthView);
    } else {
      setEntries(monthlyThoughts);
    }
    // You might consider adding appropriate dependencies here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeMonthView = (month: string) => {
    setMonthView(month);
    fetchData(month);
  };

  const onEnterSearch = async (query: string) => {
    const searchedThoughts = await postSearch(query);
    setThoughtEntries(searchedThoughts);
  };

  const SearchBar = () => (
    <Grid container item xs sx={{ alignItems: "center", padding: "16px" }}>
      <Search onEnterSearch={onEnterSearch} />
    </Grid>
  );

  const Filter = (
    <Filters
      on_change_descending={onChangeDescending}
      on_change_sentiment_idx={onChangeSentimentIdx}
      sentiment_idx={sentimentIdx}
      descending={descending}
      search_bar={<SearchBar />}
    />
  );

  return (
    <Grid container item xs height="100%" direction="column" width="100%">
      <Grid
        container
        item xs
        sx={{
          width: "100%",
          padding: "2px",
          borderRadius: "16px",
          border: "2.4px solid #CEE7F7",
          backgroundClip: "padding-box",
        }}
        direction="column"
      >
        <Grid
          container
          item xs
          sx={{ backgroundColor: "white", borderRadius: "12px" }}
          direction="column"
        >
          <Grid
            container
            item
            paddingTop="8px"
            sx={{
              backgroundColor: "#d5e7f2",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            {Filter}
          </Grid>
          <Divider />
          <Entries thoughts={thoughtEntries} mutable={false} date_range="Month" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VaultContent; 