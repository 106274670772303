import { persistReducer, persistStore } from 'redux-persist';

import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // You can choose local storage or other storage engines
import thunk from 'redux-thunk';
import { rootReducer } from './Reducers';

// Import redux-thunk

// Reducers
const persistConfig = {
	key: 'root', // key is required and should be a unique string
	storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Use RootState and ListAction to create the Store
// export const store = createStore(persistedReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)

	// devTools: process.env.NODE_ENV !== 'production',
	// middleware: [thunk]
});
// store.dispatch(post_thought);
// store.dispatch(login);

export const persistor = persistStore(store);

export function clearPersistedState() {
	persistor.purge();
}

function scheduleMidnightClearPersistedState() {
	const now: Date = new Date();
	const midnight: Date = new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate() + 1,
		0, // Hour
		0, // Minute
		0 // Second
	);

	const timeUntilMidnight = midnight.getTime() - now.getTime();

	setTimeout(() => {
		clearPersistedState();
		sessionStorage.clear();
		scheduleMidnightClearPersistedState(); // Schedule the next midnight clear
	}, timeUntilMidnight);
}

scheduleMidnightClearPersistedState();
// clearPersistedState();
export default store;
