import * as React from 'react';

import { CheckCircle, CircleOutlined, InfoRounded } from '@mui/icons-material';
import { Checkbox, Grid, Tooltip, Typography } from '@mui/material';
import { EMOTION_DESCRIPTOR, get_cta_color } from '../../../Util/Helper';

import Button from '@mui/material/Button';
import mascot_meditating from '../../../assets/images/logo/mascot_meditating.svg';
import { StyledDropdown } from '../../../components/molecules/StyledDropdown';

export const ThoughtEmotionDropdown = (props: {
	anchorEl: null | HTMLElement;
	open: boolean;
	onClose: (emotions: string[]) => void;
	emotions: string[];
	setEmotions: (emotions: string[]) => void;
	sentiment: string;
	options: string[];
	thoughtUuid: number;
}) => {
	const [user_selected_emotions, setUserSelectedEmotions] = React.useState(
		props.emotions
	);
	const handleClose = () => {
		props.setEmotions(user_selected_emotions);
		props.onClose(user_selected_emotions);
	};
	const onCheck = (checked: boolean, emotion: string) => {
		if (checked) {
			setUserSelectedEmotions([...user_selected_emotions, emotion]);
		} else {
			setUserSelectedEmotions(
				user_selected_emotions.filter((e) => e !== emotion)
			);
		}
	};
	if (!props.anchorEl) {
		return <></>;
	}

	return (
		<Grid
			container
			item
			sx={{
				backgroundImage:
					'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
			}}
		>
			<Grid container item xs direction="column" paddingBottom="0px">
				<Grid
					container
					item
					xs
					justifyContent="center"
					direction="column"
				>
					{/* <Grid container item xs direction="row" width="100%"></Grid> */}
					<Grid
						container
						item
						direction="column"
						sx={{
							backgroundColor: 'white',
							borderRadius: '8px'
						}}
					>
						<Grid
							container
							// spacing={1}
							direction="row"
							alignItems="center"
							sx={{
								backgroundColor: '#F8F8F8',
								padding: '8px 16px',
								display: 'flex'
							}}
						>
							<Grid item xs={2} marginRight="8px">
								<img
									src={mascot_meditating}
									style={{ height: '32px' }}
								/>
							</Grid>
							<Grid item xs>
								<Typography
									sx={{
										fontSize: '12px',
										fontWeight: 500,
										fontFamily: 'arial'
									}}
								>
									I am feeling...
								</Typography>
							</Grid>
						</Grid>
						<Grid container padding="8px">
							{props.options.map((o) => (
								<ComputedEmotionOption
									option={o}
									onCheck={onCheck}
								/>
							))}
						</Grid>
						<ConfirmButton
							disabled={user_selected_emotions?.length === 0}
							onClick={handleClose}
						/>
					</Grid>

					{/* <Comple */}
				</Grid>
			</Grid>
		</Grid>
	);
};

export const ConfirmButton = (props: {
	disabled: boolean;
	onClick: () => void;
}) => {
	return (
		<Grid container padding="8px">
			<Button
				onClick={props.onClick}
				variant="contained"
				disableElevation
				disabled={props.disabled}
				fullWidth
				disableRipple
				sx={{
					padding: '4px',
					borderRadius: '16px',
					textTransform: 'none',
					backgroundColor: get_cta_color(),
					'&:hover': { backgroundColor: get_cta_color() }
				}}
			>
				<Typography
					sx={{
						// cursor: "default",
						fontFamily: 'arial',
						// color: props.options.length === 0 ? "gray" : "#74A899",
						fontSize: '12px',
						lineHeight: '18px',
						fontWeight: 600,
						wrap: 'wrap'
					}}
				>
					Confirm
				</Typography>
			</Button>
		</Grid>
	);
};

export const ComputedEmotionOption = (props: {
	option: string;
	onCheck: (isChecked: boolean, emotion: string) => void;
}) => {
	const [checked, setChecked] = React.useState(false);
	const onCheck = () => {
		setChecked(!checked);
		props.onCheck(!checked, props.option);
	};
	return (
		<Grid
			container
			item
			alignItems="center"
			justifyContent="center"
			sx={{
				'&:hover': { backgroundColor: '#E5E5E5' },
				// height: '32px',
				borderRadius: '16px',
				cursor: 'pointer'
				// backgroundColor: 'red'
			}}
			onClick={onCheck}
		>
			<Checkbox
				checked={checked}
				sx={{ marginRight: '8px' }}
				disableRipple
				icon={<CircleOutlined sx={{ width: '16px', height: '16px' }} />}
				checkedIcon={
					<CheckCircle
						sx={{
							backgroundImage: '',
							color: get_cta_color(),
							width: '16px',
							height: '16px'
						}}
					/>
				}
			/>
			<Typography sx={{ fontSize: '12px' }}>{props.option}</Typography>

			<Grid
				container
				item
				xs
				justifyContent="flex-end"
				alignItems="center"
				marginRight="8px"

				// sx={{ backgroundColor: 'red' }}
			>
				<Tooltip
					title={EMOTION_DESCRIPTOR[props.option]}
					PopperProps={{
						modifiers: [
							{
								name: 'zIndex',
								enabled: true,
								phase: 'write',
								fn: ({ state }) => {
									state.elements.popper.style.zIndex = 214748364725;
								}
							}
						]
					}}
				>
					<InfoRounded
						fontSize="small"
						sx={{ color: get_cta_color(0.5), width: '16px' }}
					/>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

export const EmotionMenu = (props: {
	anchorEl: null | HTMLElement;
	open: boolean;
	onClose: (emotions: string[]) => void;
	emotions: string[];
	setEmotions: (emotions: string[]) => void;
	sentiment: string;
	options: string[];
	thoughtUuid: number;
}) => {
	return (
		<StyledDropdown
			anchorEl={props.anchorEl}
			open={props.open}
			onClose={() => props.onClose(props.emotions)}
			sx={{
				maxHeight: '200px'
				// width: "400px",
			}}
		>
			<Grid container item xs direction="column" height="100px">
				{props.options.map((emotion, idx) => (
					<Grid
						container
						item
						xs
						// padding="8px"
						key={idx}
						onClick={() =>
							props.setEmotions(
								props.emotions.includes(emotion)
									? props.emotions.filter(
											(x) => x !== emotion
									  )
									: [...props.emotions, emotion]
							)
						}
						alignItems="center"
						sx={{
							'&:hover': {
								backgroundColor: '#F5F5F5'
							},
							cursor: 'pointer'
							// height: "20px",
							// marginBottom: "8px",
						}}
					>
						<Checkbox
							disableRipple
							sx={{
								width: '16px',
								height: '16px',
								marginRight: '12px'
							}}
							icon={
								<CircleOutlined
									style={{
										color: '#7F7D83',
										width: '16px',
										height: '16px'
									}}
								/>
							}
							checkedIcon={
								<CheckCircle
									style={{
										// color: 'white',
										width: '16px',
										height: '16px',
										color: get_cta_color()
									}}
								/>
							}
							checked={props.emotions.includes(emotion)}
						/>

						<Typography
							sx={{
								cursor: 'pointer',
								color: '#7F7D83',
								fontWeight: 450,
								fontSize: '14px',
								lineHeight: '20px',
								letterSpacing: '-0.5px'
							}}
						>
							{emotion}
						</Typography>
					</Grid>
				))}
			</Grid>
		</StyledDropdown>
	);
};
