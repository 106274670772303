// import './LandingPage.css';

import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { get_beige, get_cta_color } from '../Util/Helper';

import { useAuth0 } from '@auth0/auth0-react';
import { LogoutRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import gradient_circle from '../assets/images/svg-files/gradient_circle.svg';
import { Icons } from '../components/organisms/Icons';

export default function LandingPage() {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      sessionStorage.getItem('authorized_state') === 'authorized' &&
      sessionStorage.getItem('authenticated_state') === 'authenticated'
    ) {
      navigate('/space');
    } else if (sessionStorage.getItem('authorized_state') === 'authorized') {
      navigate('/space/gringotts');
    }
  }, []);
  return (
    <Grid
      container
      item
      xs
      direction="column"
      ref={containerRef}
      alignItems="center"
      justifyContent="center"
      sx={{
        height: `100vh`,
        overflow: 'hidden',
        backgroundColor: get_beige(),
      }}
      style={{ cursor: 'default' }}
      position="relative"
    >
      <Grid
        container
        item
        xs
        position="absolute"
        top="50%"
        left="50%"
        borderRadius="50%"
        width="420px"
        height="420px"
        sx={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        <img src={gradient_circle} />
      </Grid>
      <Grid container item xs position="absolute" bottom="0" left="0">
        <Icons.SunShape height="330px" />
      </Grid>
      <Grid container item xs position="absolute" bottom="0" right="0" width="480px">
        <Icons.GreenSquiglyYellowCircle height="230px" />
      </Grid>
      <Grid container item xs position="absolute" top="0" left="0">
        <Icons.PurpleSquiglyGreenCircle height="444px" />
      </Grid>
      <Grid container item xs justifyContent="center" alignItems="center" direction="column">
        <Header />
      </Grid>
    </Grid>
  );
}

const Header = () => {
  const { loginWithRedirect } = useAuth0();
  const handleLogin = async () => {
    try {
      sessionStorage.setItem('authorized_state', 'authorized');
      await loginWithRedirect({
        authorizationParams: {
          scope: 'openid profile email offline_access',
          redirectUri: `${window.location.origin}/auth/callback`,
        },
      });
    } catch (error) {
      // Handle login error
      console.error('Login error:', error);
    }
  };
  return (
    <Grid
      container
      item
      // xs
      justifyContent="center"
      alignItems="center"
      sx={{
        paddingRight: '24px',
        paddingLeft: '24px',
        // paddingTop: '24px',
        height: '80px',
      }}
    >
      <Button
        onClick={handleLogin}
        variant="outlined"
        // endIcon={<ChevronRight />}
        sx={{
          position: 'absolute',
          // marginTop: '32px',
          width: '620px',
          height: '80px',
          borderRadius: '40px',
          // height: '54px',
          backgroundColor: 'white',
          fontSize: '16px',
          lineHeight: '22px',
          fontWeight: 450,
          borderColor: get_cta_color(),
          // color: get_cta_color(),

          fontFamily: 'arial',
          border: '1 px solid' + get_cta_color(),
          // marginTop: '48px',
          textTransform: 'none',
          borderWidth: '4px',
          '&:hover': {
            borderColor: get_cta_color(),
            borderWidth: '4px',
            backgroundColor: 'white',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '32px',
            fontWeight: 550,
            // fontFamily: 'arial',
            marginRight: '24px',
            color: get_cta_color(),
          }}
        >
          Click to enter Sage
        </Typography>
        <LogoutRounded
          sx={{
            color: get_cta_color(),
            width: '32px',
            height: '32px',
          }}
        />
        {/* <img src={sageCircle} width="120px" /> */}
      </Button>
    </Grid>
  );
};
export const GreenOutlineButton = (props: {
  onClick: any;
  text: string;
  width?: string;
  height?: string;
}) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        width: props.width || undefined,
        height: props.height || undefined,
        textTransform: 'none',
        backgroundColor: 'white',
        border: '1px solid #74A899',
        boxShadow: '#74A899',
        radius: '12px',
      }}
    >
      <Typography
        sx={{
          color: '#74A899',
          fontSize: '14px',
          fontWeight: 450,
          lineHeight: '20px',
          spacing: '-0.18px',
        }}
      >
        {props.text}
      </Typography>
    </Button>
  );
};
