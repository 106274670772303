import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { is_authorized } from '../Util/Redux/AsyncMiddleware';

const CallbackPage = () => {
	const { isAuthenticated, user, logout, isLoading } = useAuth0();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		const checkAuthorization = async () => {
			if (isAuthenticated) {
				const hasAccess = await dispatch(is_authorized(user.email));
				if (hasAccess) {
					navigate('/space/gringotts'); // Navigate to the intended page
				} else {
					navigate('/waitlist');
					// logout({ returnTo: window.location.origin + "/waitlist" });
				}
			}
		};
		checkAuthorization();
	}, [isAuthenticated, user, navigate, isLoading]);

	useEffect(() => {
		navigate('/');
	}, [logout]);

	return <div>Loading...</div>;
};

export default CallbackPage;
