import { Grid, LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { DARK_GRAY, get_cta_color, get_sky_blue, get_text_color } from '../Util/Helper';

interface QuestProgressBarProps {
	current: number;
	total: number;
	title: string;
	icon: React.ReactNode;
}

const BG_COLOR ='#c4dff2'
//  get_sky_blue()
const BORDER_COLOR = get_sky_blue(0.5)
const CENTER_COLOR = get_cta_color(0.5);

const QuestProgressBar: React.FC<QuestProgressBarProps> = ({
	current,
	total,
	title,
	icon
}) => {
	// Calculate the actual progress value.
	const progressValue = (current / total) * 100;
	// When current is 0, force a minimal progress display (e.g. 3%).
	const displayProgress = current === 0 ? 3 : progressValue;

	return (
		<Box sx={{ width: '100%', paddingLeft: '8px' }}>
			{/* Title above the progress bar */}
			<Grid container item alignItems="center">
	
			<Grid container item xs>
			<Typography
				sx={{
					color: get_text_color(),
					fontSize: '12px',
					// mb: 1,
					fontFamily: 'arial',
					fontWeight: 600,
				}}
			>
				{title}
			</Typography></Grid>
			</Grid>
			{/* Container for progress bar with relative positioning */}
			<Box sx={{ position: 'relative', width: '100%', marginTop: '8px' }}>
				{/* Outer container provides the light gray background, 2px padding, and green border */}
				<Box
					sx={{
						backgroundColor: '#F0F0F0',
						borderRadius: '20px',
						p: '2px',
						border: '2px solid '+ BORDER_COLOR
					}}
				>
					{/* The inner progress bar with transparent track so that the outer background shows */}
					<LinearProgress
						variant="determinate"
						value={displayProgress}
						sx={{
							height: '16px',
							borderRadius: '16px',
							backgroundColor: 'transparent',
							'& .MuiLinearProgress-bar': {
								borderRadius: '16px',
								backgroundColor: BG_COLOR,
							},
						}}
					/>
				</Box>
				{/* Overlay current/total text inside the progress bar */}
				<Typography
					variant="body1"
					sx={{
						position: 'absolute',
						top: 0,
						width: '100%',
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						pr: '8px',
						fontWeight: 600,
						color: current > 1? '#84A9E0': DARK_GRAY,
						fontSize: '14px',
					}}
				>
					{`${current}/${total}`}
				</Typography>
			</Box>
		</Box>
	);
};


export default QuestProgressBar;
