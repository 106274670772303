import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { setAuthenticated, setAuthorized } from "../Util/Redux/Middleware";

import { useAuth0 } from "@auth0/auth0-react";
import { LogoutRounded } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import sageCircle from "../assets/images/logo/sageCircle.svg";
import sageLogo from "../assets/images/logo/sageLogo.png";
import { clearPersistedState } from "../Util/Redux/Store";

export function LoginSignUpButton(props: {
  text: string;
  marginLeft?: string;
  marginBottom?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      fullWidth={props.fullWidth}
      onClick={() => props.onClick && props.onClick()}
      sx={{
        borderRadius: "140px",
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        width: "280px",
        height: "88px",
        "&:hover": {
          backgroundImage: "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
        },
        // backgroundColor: get_cta_color(),
        backgroundImage: "linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)",
        textTransform: "none",
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          lineHeight: "20px",
          fontWeight: 600,
          color: "#333333",
          letterSpacing: "-0.05px",
          fontFamily: "arial",
        }}
      >
        {props.text}
      </Typography>
    </Button>
  );
}
function Waitlist() {
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const onLogout = () => {
    dispatch(setAuthorized(false));
    dispatch(setAuthenticated(null));
    clearPersistedState();
    sessionStorage.clear();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  return (
    <Grid
      container
      item
      xs
      justifyContent="center"
      alignItems="center"
      height="100vh"
      direction="column"
    >
      <Tooltip title="Leave Sage Space">
        <IconButton
          sx={{
            marginRight: "16px",
            color: "#AEB2BC",
            width: "46px",
            height: "46px",
          }}
          //   onMouseEnter={() => setAnimateLock(true)}
          //   onMouseLeave={() => setAnimateLock(false)}
          onClick={onLogout}
        >
          <LogoutRounded />
        </IconButton>
      </Tooltip>
      <Grid
        container
        item
        xs={11}
        justifyContent="center"
        alignItems="center"
        direction="column"
        width="400px"
        height="452px"
      >
        <Grid container item justifyContent="center" marginBottom="24px">
          <img src={sageCircle} style={{ width: "76.22", height: "64px" }} />
          <img
            src={sageLogo}
            style={{
              marginLeft: "20px",
              width: "76.22",
              height: "64px",
            }}
          />
        </Grid>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "12px",
            lineHeight: "40px",
            letterSpacing: "-3%",
            marginBottom: "48px",
            marginTop: "24px",
            textAlign: "center",
            color: "#2c2c2c",
          }}
        >
          Thank you for your interest! <br /> We've added you to our waitlist.
        </Typography>
        {/* <LoginSignUpButton
					text="Log in or Sign up"
					onClick={handleLogin}
				/> */}
      </Grid>

      {/* <Grid
				container
				item
				xs
				justifyContent="space-between"
				alignItems="flex-end"
				padding="24px"
			>
				<Typography
					sx={{
						fontSize: '14px',
						color: '#4F4D55',
						fontWeight: 400,
						lineHeight: '20px',
						letterSpacing: '-0.05px'
					}}
				>
					{' '}
					©2024 Sage{' '}
				</Typography>
				<Typography
					sx={{
						fontSize: '14px',
						color: '#4F4D55',
						fontWeight: 400,
						lineHeight: '20px',
						letterSpacing: '-0.05px'
					}}
				>
					{' '}
					Privacy Policy{' '}
				</Typography>
			</Grid> */}
    </Grid>
  );
}

export default Waitlist;
