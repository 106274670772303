export const RANDOM_PROMPTS: string[] = [
  "What was a moment from this year you felt the most alive? Why?",
  "When did you last connect with your inner child? How did it feel?",
  "Is there anything you should let go of? Why haven’t you?",
  "How can you romanticize this season of your life?",
  "What currently fulfills you? List as many things as you can.",
  "What are you working on right now? How can you improve it?",
  "How can you love yourself a little more today?",
  "What can you do to make this week more fulfilling?",
  "What is one thing you've always wanted to try or start?",
  "Schedule a time to try/start that thing and commit to it.",
  "What areas of your life need the most love? Write a goal for improvement.",
  "How are you feeling right now, truly?",
  "How can you take time for yourself today?",
  "How did this week go? What were your wins and lessons?",
  "What do you appreciate most about being in your own company?",
  "What is something you accomplished this year that you're proud of?",
  "Describe your ideal day from start to finish.",
  "What does success mean to you, personally?",
  "Write a letter to your future self one year from now.",
  "What do you want to leave behind as you enter a new chapter?",
  "List 10 things that make you genuinely happy.",
  "What new habits do you want to build next year?",
  "What was your biggest lesson learned this year?",
  "What is one fear you want to overcome?",
  "How do you want to show up for yourself next year?",
  "How do you feel about yourself at this moment?",
  "What encouragement would you give yourself if you were a friend?",
  "Make a list of things that bring you joy and peace.",
  "What excites you most about the future?",
  "What is something you’d love to do but feel you can’t? Why?",
  "Complete this sentence: Today I’m moving towards…",
  "What’s one thing you can do this week to care for yourself?",
  "What’s something fun you haven’t done in a while?",
  "If you could change one thing about your life, what would it be and why?",
  "What are you looking forward to in the coming months?",
  "What can you do just for yourself this weekend?",
  "What’s a book, song, movie, or show you loved recently? Describe why.",
  "What’s one thing you want to achieve by the end of the year?",
  "How do the colder months make you feel?",
  "What feels missing from your life right now?",
  "Who is your biggest support system right now?",
  "What’s something you could do for your body today?",
  "What’s one thing you could do for your mind today?",
  "What’s one thing you could do for your soul today?",
  "What will bring you comfort or peace this week?",
  "What are 3 things on your happiness menu?",
  "What’s a place that brings you joy and why?",
  "Do you need a weekend of rest or excitement? What do you need more of?",
  "What’s one thing you could do today to set yourself up for success next week?",
  "What’s a small change that could make a big difference in your daily life?",
  "What’s one thing you’re deeply grateful for right now?",
  "What do you love most about your current self?",
  "What’s something you’ve grown out of that once mattered to you?",
  "Who has had a positive impact on your life lately? How can you thank them?",
  "What’s a lesson you learned the hard way that made you stronger?",
  "If you had unlimited time and money, what would you spend your days doing?",
  "What’s a simple pleasure that never fails to make you smile?",
  "What’s something you’re currently healing from?",
  "If your future self could send you a message, what do you think it would say?",
  "Write a love letter to yourself.",
  "What’s a moment of kindness that you witnessed or experienced recently?",
  "What’s something from your childhood that still brings you joy?",
  "How do you define happiness in your own words?",
  "What’s a goal you’ve been putting off? How can you start working on it?",
  "If today was your last day, how would you spend it?",
  "What’s one thing you’d like to be remembered for?",
  "What does self-care look like for you? How can you practice it more?",
  "Describe a time you felt completely at peace.",
  "What’s a quote or mantra that resonates with you right now?",
  "What’s something new you’ve learned recently?",
  "How do you want to feel at the end of today?",
  "What’s a small act of kindness you can do for someone this week?",
  "What do you wish you could tell your younger self?",
  "What’s an aspect of your personality that you love?",
  "What’s something you’ve been avoiding that you know would help you grow?",
  "What are your biggest priorities in life right now?",
  "How can you bring more creativity into your daily routine?",
  "What’s an experience or place you’d love to visit one day?",
  "What’s a personal tradition you cherish?",
  "What are five things you’re grateful for at this moment?",
  "How can you create more balance in your life?",
  "What’s a challenge you’ve overcome that made you stronger?",
  "If you had to describe yourself in three positive words, what would they be?",
  "What’s a habit you’ve built that has improved your life?",
  "What’s something you’ve done that your past self would be proud of?",
  "What’s a dream or vision you have for your future?",
  "What’s something you can do today that your future self will thank you for?",
  "How can you cultivate more joy in your daily routine?",
  "What’s a self-limiting belief you’re ready to let go of?",
  "If you could spend the day doing anything, with no limitations, what would it be?",
  "What’s one way you can step outside your comfort zone this month?",
  "Who inspires you and why?",
  "What’s a beautiful memory that still makes you smile?",
  "What’s something that recently made you laugh?",
  "What’s a goal you achieved that once felt impossible?",
  "How can you be kinder to yourself today?",
  "What does your heart need more of right now?",
  "If you could master any skill instantly, what would it be and why?",
  "What’s something you’re learning to embrace about yourself?",
  "What’s one way you can show love to the people in your life today?",
  "How do you want to grow as a person in the next year?",
  "What’s a tradition or routine that brings you comfort?",
  "What’s something that makes you feel strong and capable?",
  "What’s a personal win, no matter how small, that you experienced recently?",
  "How do you define a meaningful life?",
];

