import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { headerHeight } from '../Util/Config';
import MainLayout from '../components/templates/MainTemplate';
import HomePage from './HomePage';
import VaultPage from './VaultPage';

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('authorized_state') !== 'authorized') {
      navigate('/');
    }
    if (sessionStorage.getItem('authenticated_state') !== 'authenticated') {
      navigate('/space/gringotts');
    }
  }, [navigate]);
  const [page, set_page] = useState('space');
  return (
    <MainLayout page={page} set_page={set_page}>
      <Grid
        container
        item
        xs
        width="100%"
        // ref={containerRef}
        sx={{
          height: `calc(100vh - ${headerHeight})`,
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory',
        }}
      >
        
        <Grid
          container
          item
          alignItems="center"
          justifyContent="center"
          sx={{
            height: `calc(100vh - ${headerHeight})`,
            scrollSnapAlign: 'start',
            padding: '24px 0',
          }}
        >
          {page === 'space' ? <HomePage /> : <VaultPage />}
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Dashboard;
