import {
  Grid,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  CHARCOAL,
  DARK_TEXT,
  LIGHT_TEXT,
  SubmitButton,
  get_beige,
  get_cta_color,
  get_light_gray,
} from '../../Util/Helper';

import styled from '@emotion/styled';
import { Theme } from '@mui/material/styles';
import { Icons } from '../../components/organisms/Icons';
import ThoughtBar from '../../quest/thought/feed/ThoughtBar';
import ThoughtEntry from '../../quest/thought/feed/ThoughtEntry';
import { Thought } from '../../Util/Redux/Schema';
import Introduction from './Introduction';

// Container with responsive height and proper overflow handling
const TutorialContainer = styled(Grid)(({ theme }: { theme: Theme }) => ({
  // Use theme.spacing for consistent spacing units
  padding: theme.spacing(2),
  // Flexible height constraints
  minHeight: 'min(80vh, 680px)',
  maxHeight: '90vh',
  // Prevent content overflow
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  // Responsive adjustments
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    minHeight: 'calc(100vh - 56px)',
  },
  // Ensure container doesn't exceed viewport
  [theme.breakpoints.up('md')]: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
}));

// Content section with proper scrolling and spacing
const ContentSection = styled(Grid)(({ theme }: { theme: Theme }) => ({
  // Allow content to scroll while maintaining container size
  flex: 1,
  overflow: 'auto',
  // Use theme spacing consistently
  padding: theme.spacing(2),
  // Flexbox for better content organization
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2), // Consistent spacing between elements
  // Responsive padding
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    gap: theme.spacing(1),
  },
  // Prevent horizontal scrolling
  '& > *': {
    maxWidth: '100%',
    boxSizing: 'border-box',
  },
}));

// Custom connector with animation for "in-progress" state
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '@keyframes move': {
    '0%': {
      backgroundPosition: '100% 0',
    },
    '100%': {
      backgroundPosition: '0 0',
    },
  },
  '& .MuiStepConnector-line': {
    height: 3,
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#ccc', // Default gray line
    transition: 'background-color 0.3s',
  },
}));

// Custom icons component
const StepIcon = (props: StepIconProps) => {
  const { completed } = props;

  // Dynamic icons
  const icons: Record<string, React.ReactNode> = {
    1: <Icons.Sun style={{ color: completed ? '' : get_light_gray() }} />,
    2: <Icons.Flash style={{ color: completed ? '' : get_light_gray() }} />,
    3: completed ? <Icons.Logo height="24px" /> : <Icons.LogoDisabled height="24px" />,
  };
  return icons[String(props.icon)];
};

// Main Stepper component
const AnimatedStepper = (props: { active_step: number }) => {
  const steps = ['Add Thought and Emotion', 'Sage Thought', 'Sage Report'];
  return (
    <Stepper
      activeStep={props.active_step}
      alternativeLabel
      connector={
        <StepConnector
          sx={{
            '& .MuiStepConnector-line': {
              borderColor: (theme) => (props.active_step === 1 ? 'red' : 'red'),
              height: '3.8px',
              border: 'none',
              borderRadius: '4px',
              backgroundColor: props.active_step === 1 ? 'red' : '#ccc',
              transition: 'background-color 0.3s',
            },
          }}
        />
      }
      style={{ width: '100%' }}
    >
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel StepIconComponent={StepIcon}></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export const DEMO_THOUGHTS = [
  {
    thought: "I'm so excited to use Sage to explore and grow on my self-discovery journey!",
    computed_top_emotions: ['Excitement', 'Anticipation', 'Curiosity', 'Joy', 'Euphoria'],
    uuid: '1',
    user_selected_emotions: ['Excitement', 'Joy', 'Curiousity'],
    created_at: new Date('2025-01-27T20:38:00-06:00'),
    sentiment: 'joy',
    reframed: '',
  },
  {
    thought:
      'Did I really just spend 2 hours scrolling? How is it time for bed already my day just slipped by 🫤',
    computed_top_emotions: ['Distraction', 'Boredom', 'Inadequacy'],
    uuid: '2',
    user_selected_emotions: ['Distraction'],
    created_at: new Date('2025-01-27T20:38:00-06:00'),
    sentiment: 'negative',
    reframed: '',
  },
];

export default function Tutorial(props: { on_close: () => void; open: boolean }) {
  const [page, set_page] = useState(-1);
  return (
    <Grid container item xs direction="column" justifyContent="center" alignItems="center">
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        direction="column"
        sx={{
          height: '100%',
          backgroundColor: get_beige(),
          borderRadius: '24px',
          border: page !== 3 ? '4px solid ' + get_cta_color(0.25) : '',
        }}
      >
        <Grid container item xs direction="column" justifyContent="center" alignItems="center">
          {page === -1 ? (
            // <Grid container item xs sx={{ borderRadius: '32px' }}>
            <Introduction set_page={set_page} on_close={props.on_close} />
          ) : // </Grid>
          [0, 1, 2].includes(page) ? (
            <SelectEmotion
              page={page}
              set_page={set_page}
              on_close={props.on_close}
              thought={
                page === 2
                  ? { ...DEMO_THOUGHTS[1] }
                  : {
                      ...DEMO_THOUGHTS[page],
                      user_selected_emotions: [],
                      sentiment: 'unknown',
                    }
              }
            />
          ) : (
            <WriteThought page={page} set_page={set_page} on_close={props.on_close} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export const WriteThought = (props: {
  page: number;
  set_page: (page: number) => void;
  on_close: () => void;
}) => {
  return (
    <Template
      on_close={props.on_close}
      hide_submit
      disable_submit={true}
      page={props.page}
      set_page={props.set_page}
      title="Share your first thought to access your space"
      fun_fact={
        <span>
          Writing down your thoughts and emotions helps clarify your feelings and identify patterns,
          leading to deeper self-awareness. Practicing this process will make it easier to handle
          emotions in real-life situations.
        </span>
      }
      note={
        <span>
          Creating this for you has been such a joyful experience—we've learned so much along the
          way, and we're excited to share it all with you. We truly hope Sage brings you clarity,
          support, and inspiration!
          <br /> <br />
          With light & love, the Sage Team ❤️
        </span>
      }
      instruction_icon={
        <Icons.Cloud height="32px" style={{ marginLeft: '32px', color: 'white' }} />
      }
      // instruction={
      //   "Write what's on your mind and press Enter to access your dashboard."
      //   // " Then, go through the entire flow: select the associated emotion (positive or negative), and work through it as we've shown. This will help you build the note of emotional awareness.'
      // }
      body={
        <ThoughtBar
          placeholder="What's on your mind? (Hint: hit Enter to save)"
          on_add_thought_additional_event={props.on_close}
        />
      }
    />
  );
};

export const SelectEmotion = (props: {
  thought: Thought;
  page: number;
  set_page: (page: number) => void;
  on_close: () => void;
}) => {
  const [thought, set_thought] = useState({ ...props.thought });
  useEffect(() => {
    set_thought({ ...props.thought });
  }, [props.thought]); // Runs whenever props.example changes
  const [show_sage_worksheet, set_show_sage_worksheet] = useState(false);

  const Body = () => {
    return (
      <Grid container item xs>
        <Grid
          container
          item
          xs={12}
          direction="column"
          sx={(theme) => ({
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            border: '1px solid #F1F1F1',
            borderRadius: theme.spacing(2),
            padding: '32px 0',
            marginRight: 0,
          })}
        >
          <Grid container item xs direction="column">
            <ThoughtEntry
              open_icon_tooltip
              mutable
              show_sage_button={props.page === 2 && !show_sage_worksheet}
              thought={thought}
              default_to_time
              animate={props.page !== 2}
              on_click_sage_button={() => {
                set_show_sage_worksheet(true);
              }}
              set_selected_emotions={(emotions: string[]) =>
                set_thought({
                  ...thought,
                  user_selected_emotions: emotions,
                  sentiment: props.page === 0 ? 'positive' : 'negative',
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const title =
    props.page === 0
      ? 'Recognizing Positive Emotions'
      : props.page === 1
        ? 'Acknowledging Harsh Emotions'
        : 'Processing Harsh Thoughts';
  const fun_fact =
    props.page === 0
      ? 'Great job! Recognizing positive emotions is the first step toward boosting your mood and focusing on what brings you joy. This practice helps you build gratitude and awareness of your strengths.'
      : props.page === 1
        ? 'Acknowledging negative emotions is key to understanding and working through challenges. This step can guide you in processing difficult feelings, making them less overwhelming.'
        : show_sage_worksheet
          ? "To keep it a surprise, we won't reveal everything here, but this AI-powered tool is designed to help you navigate harsh thoughts. It offers thoughtful acknowledgment, guidance, and a more positive perspective on negative thoughts."
          : '';
  const instructions =
    props.page < 2
      ? 'Click on the cloud icon to select emotions associated with this thought'
      : 'Click on the sage button to interact with harsh thoughts.';
  return (
    <Template
      fun_fact={fun_fact}
      on_close={props.on_close}
      title={title}
      note=""
      instruction_icon={<Icons.Cloud />}
      instruction={instructions}
      body={<Body />}
      page={props.page}
      set_page={props.set_page}
      disable_submit={
        props.page === 2 ? !show_sage_worksheet : thought.user_selected_emotions.length === 0
      }
    />
  );
};

// Template component with consistent spacing and responsive layout
export const Template = (props: {
  hide_submit?: boolean;
  fun_fact: ReactNode;
  title: string;
  note: string;
  instruction_icon: ReactElement;
  instruction: string;
  body: ReactElement;
  page: number;
  set_page: (page: number) => void;
  on_close: () => void;
  disable_submit: boolean;
}) => {
  return (
    <Grid container item xs direction="column" justifyContent="center" alignItems="center">
      <Grid container item overflow="hidden">
        <Grid container item position="absolute" top={20} left={20}>
          <Icons.PurpleSquiglyGreenCircle height="348px" />
        </Grid>
        <Grid container item position="absolute" top="48px" left="748px">
          {props.page === 0 ? (
            <Icons.Sun sx={{ fontSize: 160, opacity: 0.8 }} />
          ) : props.page === 1 ? (
            <Icons.Flash sx={{ fontSize: 160, opacity: 0.8 }} />
          ) : props.page === 2 ? (
            <Icons.Logo height="160px" />
          ) : (
            <Icons.Heart sx={{ fontSize: 160, opacity: 0.8 }} />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={4}
        marginTop="60px"
        paddingLeft="144px"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Icons.Step step={props.page + 1} />
        <Typography
          sx={{
            fontSize: '18px',
            color: CHARCOAL,
            fontFamily: 'arial',
            fontWeight: 500,
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        padding="0 32px"
      >
        <Grid container item xs={1} direction="column" justifyContent="center">
          <Grid container item xs alignItems="center">
            <Typography
              sx={{
                fontSize: '20px',
                color: DARK_TEXT,
                fontFamily: 'arial',
                margin: '0 8px',
                fontWeight: 600,
              }}
            >
              {props.instruction}
            </Typography>
          </Grid>
          {props.note && (
            <Grid container item xs padding="32px">
              <Typography
                sx={{
                  fontSize: '16px',
                  color: LIGHT_TEXT,
                  fontFamily: 'arial',
                  fontWeight: 500,
                  marginLeft: '8px',
                }}
              >
                <span>{props.note}</span>
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          sx={{
            marginTop: '8px',
          }}
        >
          <Grid container item sx={{ padding: props.page === 3 ? '0 40px' : '' }}>
            {props.body}
          </Grid>
          {!props.disable_submit && (
            <Grid
              container
              item
              xs
              alignItems="center"
              sx={{
                animation: 'fade_in 1s ease-in-out',
                padding: '0 32px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  color: LIGHT_TEXT,
                  fontFamily: 'arial',
                  fontWeight: 500,
                }}
              >
                <span>{props.fun_fact}</span>
              </Typography>
            </Grid>
          )}
        </Grid>
        {!props.hide_submit && (
          <Grid
            container
            item
            xs={2}
            paddingBottom="24px"
            justifyContent="flex-end"
            direction="column"
            width="100%"
          >
            <Grid container item xs>
              <Grid container item xs={10}>
                <SubmitButton
                  disabled={props.disable_submit}
                  fullWidth
                  text="Next"
                  onClick={() => {
                    props.set_page(props.page + 1);
                  }}
                />
              </Grid>
              <Grid container item xs alignItems="center" paddingTop="4px">
                <SubmitButton text="Skip" secondary onClick={() => props.on_close()} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
