// src/icons.tsx
import {
	ArrowDropDownRounded,
	CheckRounded,
	CloudRounded,
	CreateRounded,
	FavoriteRounded,
	FlashOnRounded,
	Home,
	LandscapeRounded,
	Menu,
	Person,
	RocketRounded,
	Settings,
	StarRounded,
	WbSunnyRounded
} from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import logo_text from '../../assets/images/logo/sageLogo.png';
import { MeteoconsLightningBoltFillAnimated } from '../../assets/svgFiles';

import introduction from '../../assets/images/introduction.png';
import mascot_holding_star from '../../assets/images/logo/mascot_holding_star.svg';
import logo_disabled from '../../assets/images/logo/sage_circle_disabled.svg';
import logo from '../../assets/images/logo/sageCircle.svg';
import click_arrow from '../../assets/images/svg-files/click_arrow.svg';

import our_privacy_promise from '../../assets/images/svg-files/our_privacy_promise.svg';
import some_ways_to_share from '../../assets/images/svg-files/some_ways_to_share.svg';
import welcome_to_sage from '../../assets/images/svg-files/welcome_to_sage.svg';

import sun_shape from '../../assets/images/svg-files/bottom_left_shape.svg';
import green_squigly_yellow_circle from '../../assets/images/svg-files/bottom_right_shape.svg';
import purple_daisy from '../../assets/images/svg-files/purple_daisy.svg';
import sage_space from '../../assets/images/svg-files/sage_space.svg';
import space from '../../assets/images/svg-files/space.svg';
import step_1 from '../../assets/images/svg-files/step_1.svg';
import step_2 from '../../assets/images/svg-files/step_2.svg';
import step_3 from '../../assets/images/svg-files/step_3.svg';
import step_4 from '../../assets/images/svg-files/step_4.svg';
import vault from '../../assets/images/svg-files/vault.svg';
import yellow_daisy from '../../assets/images/svg-files/yellow_daisy.svg';

import purple_squigly_green_circle from '../../assets/images/svg-files/top_left_shape.svg';

import sage_vault from '../../assets/images/svg-files/sage_vault.svg';

import mascot_meditating from '../../assets/images/logo/mascot_meditating.svg';
import { get_light_gray } from '../../Util/Helper';

const GREEN = '#A9D4C0';
const RED = '#FDA7A6';
const YELLOW = '#FDDFA5';
const LIGHT_GRAY = '#E5E5E5';

// Function to wrap icons and forward all props
const createIcon = (IconComponent: React.ElementType) => {
	return (props: SvgIconProps) => <IconComponent {...props} />;
};

export const CreateR = styled(CreateRounded)(({ theme }) => ({
	color: GREEN,
	fontSize: 24 // Customize size
}));
export const StarOutlineR = styled(StarRounded)(({ theme }) => ({
	// Set the inner area to light gray
	fill: get_light_gray(),
	// Target the inner path element to set its stroke (border)
	'& path': {
		stroke: GREEN,
		strokeWidth: 2.4
	},
	// Set the desired icon size
	fontSize: 24,
	// Center the icon by making it a block element and using auto margins
	display: 'block',
	margin: 'auto'
}));

export const StarR = styled(StarRounded)(({ theme }) => ({
	color: GREEN,
	fontSize: 24 // Customize size
}));
export const RocketR = styled(RocketRounded)(({ theme }) => ({
	// color: RED,
	fontSize: 24 // Customize size
}));
export const FlashR = styled(FlashOnRounded)(({ theme }) => ({
	color: RED,
	fontSize: 24 // Customize size
}));
export const CloudR = styled(CloudRounded)(({ theme }) => ({
	color: LIGHT_GRAY,
	fontSize: 24 // Customize size
}));

export const SunR = styled(WbSunnyRounded)(({ theme }) => ({
	color: YELLOW,
	fontSize: 24 // Customize size
}));

export const AirR = styled(LandscapeRounded)(({ theme }) => ({
	color: GREEN,
	// '#C2D0F1',
	fontSize: 20 // Customize size
}));

export const HeartR = styled(FavoriteRounded)(({ theme }) => ({
	color: RED,
	fontSize: 24 // Customize size
}));

export const Logo = (props: { height?: string; margin?: string }) => {
	return (
		<img
			src={logo}
			alt="logo"
			style={{ height: props.height || '20px', margin: props.margin }}
		/>
	);
};

export const LogoDisabled = (props: { height?: string }) => {
	return (
		<img
			src={logo_disabled}
			alt="logo disabled"
			style={{ height: props.height, margin: props.margin }}
		/>
	);
};
export const LogoText = (props: { height?: string; margin?: string }) => {
	return (
		<img
			src={logo_text}
			alt="logo"
			style={{ height: props.height, margin: props.margin }}
		/>
	);
};

export const WelcomeToSage = (props: { height?: string; margin?: string }) => {
	return (
		<img
			src={welcome_to_sage}
			alt="welcome_to_sage"
			style={{ height: props.height || '20px', margin: props.margin }}
		/>
	);
};

export const SomeWaysToShare = (props: {
	height?: string;
	margin?: string;
}) => {
	return (
		<img
			src={some_ways_to_share}
			alt="some_ways_to_share"
			style={{ height: props.height || '20px', margin: props.margin }}
		/>
	);
};

export const OurPrivacyPromise = (props: {
	height?: string;
	margin?: string;
}) => {
	return (
		<img
			src={our_privacy_promise}
			alt="our_privacy_promise"
			style={{ height: props.height || '20px', margin: props.margin }}
		/>
	);
};

export const Step = (props: {
	step: number;
	height?: string;
	margin?: string;
}) => {
	return (
		<img
			src={
				props.step === 1
					? step_1
					: props.step === 2
					  ? step_2
					  : props.step === 3
					    ? step_3
					    : step_4
			}
			alt="Step"
			style={{ height: props.height || '40px', margin: props.margin }}
		/>
	);
};

export const MorangaText = (props: {
	text: string;
	height?: string;
	width?: string;
	margin?: string;
}) => {
	return (
		<img
			src={
				props.text === 'space'
					? space
					: props.text === 'vault'
					  ? vault
					  : props.text === 'Sage Space'
					    ? sage_space
					    : props.text === 'Sage Vault'
					      ? sage_vault
					      : space
			}
			alt={props.text}
			style={{
				width: props.width,
				height: props.height,
				margin: props.margin
			}}
		/>
	);
};

export const PurpleSquiglyGreenCircle = (props: {
	transform?: string;
	height?: string;
	margin?: string;
	alpha?: number;
}) => {
	return (
		<img
			src={purple_squigly_green_circle}
			alt="purple_squigly_green_circle"
			style={{
				transform: props.transform,
				height: props.height || '20px',
				margin: props.margin,
				opacity: props.alpha || 1
			}}
		/>
	);
};

export const SunShape = (props: {
	height?: string;
	margin?: string;
	transform?: string;
}) => {
	return (
		<img
			src={sun_shape}
			alt="sun_shape"
			style={{
				transform: props.transform,
				height: props.height || '20px',
				margin: props.margin
			}}
		/>
	);
};

export const PurpleDaisy = (props: {
	height?: string;
	margin?: string;
	transform?: string;
	alpha?: number;
}) => {
	return (
		<img
			src={purple_daisy}
			alt="purple_daisy"
			style={{
				transform: props.transform,
				height: props.height || '20px',
				margin: props.margin,
				opacity: props.alpha || 1
			}}
		/>
	);
};
export const YellowDaisy = (props: {
	height?: string;
	margin?: string;
	transform?: string;
}) => {
	return (
		<img
			src={yellow_daisy}
			alt="yellow_daisy"
			style={{
				transform: props.transform,
				height: props.height || '20px',
				margin: props.margin
			}}
		/>
	);
};
export const GreenSquiglyYellowCircle = (props: {
	height?: string;
	margin?: string;
	alpha?: number;
}) => {
	return (
		<img
			src={green_squigly_yellow_circle}
			alt="green_squigly_yellow_circle"
			style={{
				height: props.height || '20px',
				margin: props.margin,
				opacity: props.alpha || 1
			}}
		/>
	);
};
export const ClickArrow = (props: {
	height?: string;
	margin?: string;
	rotate?: string;
}) => {
	return (
		<img
			src={click_arrow}
			alt="click_arrow"
			style={{
				transform: `rotate(${props.rotate}deg)`,
				height: props.height || '20px',
				margin: props.margin
			}}
		/>
	);
};
export const MascotMeditating = (props: {
	height?: string;
	margin?: string;
}) => {
	return (
		<img
			src={mascot_meditating}
			alt="logo"
			style={{ height: props.height, margin: props.margin }}
		/>
	);
};

export const MascotHoldingStar = (props: {
	height?: string;
	margin?: string;
}) => {
	return (
		<img
			src={mascot_holding_star}
			alt="logo"
			style={{ height: props.height, margin: props.margin }}
		/>
	);
};

export const Introduction = (props: {
	height?: string;
	margin?: string;
	width?: string;
	border_radius?: string;
}) => {
	return (
		<img
			src={introduction}
			alt="intro"
			style={{
				height: props.height,
				width: props.width,
				margin: props.margin,
				borderRadius: props.border_radius
			}}
		/>
	);
};

// Export icons with full prop support
export const Icons = {
	// Logo
	Logo: Logo,
	LogoDisabled: LogoDisabled,
	LogoText: LogoText,
	MascotMeditating: MascotMeditating,
	MascotHoldingStar: MascotHoldingStar,

	// Texts
	OurPrivacyPromise: OurPrivacyPromise,
	SomeWaysToShare: SomeWaysToShare,
	WelcomeToSage: WelcomeToSage,
	Step: Step,
	MorangaText: MorangaText,

	// Shapes
	PurpleSquiglyGreenCircle: PurpleSquiglyGreenCircle,
	SunShape: SunShape,
	GreenSquiglyYellowCircle: GreenSquiglyYellowCircle,
	PurpleDaisy: PurpleDaisy,
	YellowDaisy: YellowDaisy,

	//Tutorial
	ClickArrow: ClickArrow,

	// Wellness
	Breathe: createIcon(AirR),
	Heart: createIcon(HeartR),
	// Sentiment
	Harsh: createIcon(FlashR),
	Joy: createIcon(SunR),
	Sage: createIcon(StarR),

	Rocket: createIcon(RocketR),
	Star: createIcon(StarR),
	StarOutline: createIcon(StarOutlineR),

	Cloud: createIcon(CloudR),

	// Weather
	Sun: createIcon(SunR),
	Flash: createIcon(FlashR),
	FlashAnimated: createIcon(MeteoconsLightningBoltFillAnimated),

	// Other
	Create: createIcon(CreateR),
	Dropdown: createIcon(ArrowDropDownRounded),
	Introduction: Introduction,
	Check: createIcon(CheckRounded),
	Profile: createIcon(Person),
	Home: createIcon(Home),
	Settings: createIcon(Settings),
	Menu: createIcon(Menu)
	//   HarshFlashing:
};
