import { AutoAwesomeRounded, Circle, SubdirectoryArrowRightRounded } from '@mui/icons-material';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SentimentIcon,
  formatTime,
  getSentiment,
  getTimeDifference,
  get_cta_color,
  get_cta_light,
  get_sage_thought,
  get_sentiment_color,
} from '../../../Util/Helper';
import { create_sage_thought, postEmotions } from '../../../Util/Redux/AsyncMiddleware';
import { AppDispatch, ReframedThought, RootState, Thought } from '../../../Util/Redux/Schema';

import { selectThoughtEmotions } from '../../../Util/Redux/Middleware';
import { StyledDropdown } from '../../../components/molecules/StyledDropdown';
import { Icons } from '../../../components/organisms/Icons';
import { ThoughtEmotionDropdown } from '../emotion/ThoughtEmotionDropdown';
import SageWorksheet from '../sage_thought/Worksheet';
import SageWorksheetSummary from '../sage_thought/WorksheetSummary';
import ThoughtEntryTemplate from './ThoughtTemplate';

const WeatherIcon = () => {
  return <></>;
};
export const CloudEmotionSelect = (props: {
  animate?: boolean;
  set_selected_emotions: (emotions: string[]) => void;
  thought: Thought;
  mutable: boolean;
  open_icon_tooltip?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (!opened) {
      setOpened(true);
    }
    // setAnimate(true);
  };

  const [emotions, setEmotions] = useState(props.thought.user_selected_emotions);
  const handleClose = (emotions: string[]) => {
    if (emotions !== props.thought.user_selected_emotions) {
      props.set_selected_emotions(emotions);
    }
    setAnchorEl(null);
  };
  const isEmotionSelected =
    !open && (emotions?.length > 0 || !props.mutable) && props.thought.sentiment !== null;
  const [opened, setOpened] = useState(false);
  return (
    <Grid
      container
      item
      xs={1}
      width="48px"
      alignItems="center"
      justifyContent="center"
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
          cursor: isEmotionSelected ? 'default' : 'pointer',
        },
      }}
    >
      <Grid container item alignItems="center" justifyContent="center">
        {props.thought.tags?.includes('gratitude') ? (
          <Icons.Joy />
        ) : isEmotionSelected ? (
          <SentimentIcon
            sentiment={getSentiment(props.thought.sentiment)}
            size="24px"
            animated={props.animate}
          />
        ) : (
          <Tooltip title="Add emotions" onClick={handleClick}>
            <Grid container item width="52px">
              <Icons.Cloud />
              {/* <CloudRounded
								style={{
									color: '#7c7c7c',
									opacity: '0.2'
								}}
							/> */}
            </Grid>
          </Tooltip>
        )}

        <StyledDropdown
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(emotions)}
          sx={{
            maxHeight: '400px',
            width: '400px',
            zIndex: props.open_icon_tooltip ? 5000 : 10000,
          }}
        >
          <Grid container item xs direction="column" width="240px" sx={{}}>
            <ThoughtEmotionDropdown
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              emotions={emotions}
              setEmotions={setEmotions}
              options={props.thought.computed_top_emotions}
              thoughtUuid={props.thought.uuid}
              sentiment={props.thought.sentiment}
            />
          </Grid>
        </StyledDropdown>
      </Grid>
    </Grid>
  );
};
const MemoCloudEmotionSelect = React.memo(CloudEmotionSelect);

export default function ThoughtEntry(props: {
  thought: Thought;
  set_selected_emotions?: (emotions: string[]) => void;
  show_sage_button?: boolean;
  on_click_sage_button?: () => void;
  mutable?: boolean;
  dontShowReframed?: boolean;
  showReframe?: boolean;
  open_icon_tooltip?: boolean;
  default_to_time?: boolean;
  animate?: boolean;
}) {
  const defaultToTime = !props.mutable;
  const dispatch = useDispatch<AppDispatch>();
  const user_reframed_thought = useSelector((state: RootState) =>
    get_sage_thought(state.sageThoughts, props.thought.uuid),
  ).user_reframed_thought;
  const [show_sage_button, set_show_sage_button] = useState(
    props.mutable &&
      props.thought.sentiment?.toLowerCase() === 'negative' &&
      !user_reframed_thought,
  );
  const set_selected_emotions = async (emotions: string[]) => {
    const response = await postEmotions(props.thought.uuid, emotions);

    if (response !== null) {
      dispatch(selectThoughtEmotions(props.thought.uuid, emotions, response.data.sentiment));
      set_show_sage_button(response.data.sentiment?.toLowerCase() === 'negative');
    }
  };
  const [expanded, setExpanded] = useState(false);
  if (props.thought.tags?.includes('gratitude')) {
    return (
      <ThoughtEntryTemplate
        icon={
          <MemoCloudEmotionSelect
            animate={props.animate}
            thought={props.thought}
            mutable={props.mutable || false}
            set_selected_emotions={props.set_selected_emotions || set_selected_emotions}
            open_icon_tooltip
          />
        }
        iconArcher={user_reframed_thought ? 'source' : undefined}
        header={
          <EmotionTimeTypography
            thought={props.thought}
            defaultToTime={props.default_to_time || defaultToTime || false}
          />
        }
        thought={props.thought.thought}
        body={
          props.show_sage_button === false ? undefined : show_sage_button ? (
            <IntrospectButton
              thought={props.thought}
              on_click_sage_button={props.on_click_sage_button}
            />
          ) : undefined
        }
      />
    );
  }
  if (user_reframed_thought && props.showReframe !== false) {
    return (
      <Grid container onClick={() => setExpanded(!expanded)} item xs direction="column">
        <ThoughtEntry
          thought={props.thought}
          mutable={props.mutable}
          showReframe={false}
          set_selected_emotions={props.set_selected_emotions || set_selected_emotions}
        />
        <ReframedThoughtEntry
          defaultToTime={defaultToTime}
          reframed_thought={{
            created_at: props.thought.created_at,
            thought: user_reframed_thought,
            uuid: props.thought.uuid,
          }}
          toggleExpanded={expanded}
          thought={props.thought}
          setToggleExpanded={setExpanded}
        />
      </Grid>
    );
  }
  return (
    <ThoughtEntryTemplate
      icon={
        <MemoCloudEmotionSelect
          animate={props.animate}
          thought={props.thought}
          mutable={props.mutable || false}
          set_selected_emotions={props.set_selected_emotions || set_selected_emotions}
          open_icon_tooltip
        />
      }
      iconArcher={user_reframed_thought ? 'source' : undefined}
      header={
        <EmotionTimeTypography
          thought={props.thought}
          defaultToTime={props.default_to_time || defaultToTime || false}
        />
      }
      thought={props.thought.thought}
      body={
        props.show_sage_button === false ? undefined : show_sage_button ? (
          <IntrospectButton
            thought={props.thought}
            on_click_sage_button={props.on_click_sage_button}
          />
        ) : undefined
      }
    />
  );
}

export const ReframedThoughtEntry = (props: {
  reframed_thought: ReframedThought;
  defaultToTime: boolean;
  toggleExpanded: boolean;
  setToggleExpanded: (toggle: boolean) => void;
  thought: Thought;
  // boxRef: React.RefObject<HTMLDivElement>;
}) => {
  const [showSageSummary, setShowSageSummary] = useState(false);
  const sageThought = useSelector((state: RootState) => state.sageThoughts[props.thought.uuid]);
  return (
    <ThoughtEntryTemplate
      thought={props.reframed_thought.thought}
      iconArcher="end"
      indentIcon={true}
      icon={
        <Grid
          container
          item
          width="24px"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowSageSummary(true)}
        >
          <Tooltip title="View sage summary">
            <SubdirectoryArrowRightRounded
              sx={{
                // marginTop: '16px',
                // marginRight: '12px',
                marginLeft: '-32px',
                color: get_sentiment_color('saged').color,
              }}
            />
            <Icons.Logo margin="0 16px 0 8px" height="20px" />
          </Tooltip>
        </Grid>
      }
      header={
        <Grid
          container
          item
          xs
          // alignItems="center"
          width="100%"
          sx={{ cursor: 'default' }}
        >
          <Grid container item borderRadius="8px">
            <Grid container item xs>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#ADACB0',
                }}
              >
                A more positive way to think about this is...
              </Typography>
              {/* {sageThought?.user_reframed_thought && (
								<Typography
									sx={{
										cursor: 'default',
										fontFamily: 'arial',
										color: '#2c2c2c',
										fontSize: '12px',
										lineHeight: '20px',
										overflow: 'hidden'
									}}
								>
									{sageThought.user_reframed_thought}
								</Typography>
							)} */}
              {showSageSummary && (
                <SageWorksheetSummary
                  open={showSageSummary}
                  setOpen={setShowSageSummary}
                  thought={props.thought}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

export const IntrospectButton = (props: {
  thought?: Thought;
  on_click_sage_button?: () => void;
  margin_left?: string;
  hide_text?: boolean;
}) => {
  const [open_sage_worksheet, set_open_sage_worksheet] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const sage_thoughts = useSelector((state: RootState) => state.sageThoughts);
  const onClick = async () => {
    if (props.thought === undefined || props.on_click_sage_button) {
      props.on_click_sage_button && props.on_click_sage_button();
    } else {
      if (sage_thoughts[props.thought.uuid] === undefined) {
        await dispatch(create_sage_thought(props.thought.uuid));
      }
      set_open_sage_worksheet(true);
    }
  };
  return (
    <Tooltip title="Sage your negative thought">
      <Grid
        container
        item
        // xs
        // height="28px"
        // alignItems="center"
        marginTop={props.hide_text ? '' : '8px'}
        marginLeft={props.margin_left}
        // justifyContent="center"
        sx={{
          backgroundColor: get_cta_light(),
          // get_cta_light(),
          // backgroundColor: get_cta_color(),
          border: `2.4px solid ${get_cta_color(0.8)}`,
          // backgroundImage:
          // 	'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)',
          cursor: 'pointer',
        }}
        width={props.hide_text ? '36px' : '88px'}
        // height="44px"
        padding="4px"
        borderRadius="24px"
      >
        <Button
          fullWidth
          onClick={onClick}
          disableRipple
          sx={{
            '&:hover': {
              backgroundColor: 'transparent',
              cursor: 'pointer',
            },
            backgroundColor: 'transparent',
            cursor: 'pointer',
            padding: '0',
            borderRadius: '24px',
            textTransform: 'none',
          }}
        >
          <Grid container item xs alignItems="center">
            {/* <Icons.Sage /> */}
            <Grid
              container
              item
              // xs
              sx={{
                backgroundColor: get_cta_color(),
                // margin: "0 8px",
                marginRight: '8px',
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* <Icons.Star /> */}
              <Icons.Logo height="18px" />
            </Grid>
            {/* <Typography
              sx={{
                textAlign: "center",
                // marginLeft: "10px",
                color: "white",
                // color: get_cta_color(),
                fontFamily: "arial",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              Sage
            </Typography> */}
            {props.hide_text ? <></> : <Icons.LogoText height="16px" />}
            {/* <Icons.Sage height="16px" /> */}
          </Grid>
        </Button>
        {open_sage_worksheet && (
          <SageWorksheet
            open={open_sage_worksheet}
            setOpen={set_open_sage_worksheet}
            // setNumberOfBreaths={setNumberOfBreaths}
            thought={props.thought}
          />
        )}

        {/* <BreathingModal
          open={openBreathingOption}
          setOpen={setOpenBreathingOption}
          setCompleted={setOpenBreathingExercise}
          setNumberOfBreaths={setNumberOfBreaths}
        /> */}
        {/* <BreathingExerciseModal
					open={openBreathingExercise}
					setOpenBreathingExercise={setOpenBreathingExercise}
					setCompleted={setOpenBreathingFinishedExercise}
					numberOfBreaths={numberOfBreaths ?? 0}
				/>

				<BreathingFinishedModal
					open={openBreathingFinishedExercise}
					setOpen={setOpenBreathingFinishedExercise}
					numberOfBreaths={numberOfBreaths ?? 0}
				/> */}
        {/* <BreathingExerciseModal
				open={showCompleted}
				setOpen={setNumberOfBreaths}
				numberOfBreaths={numberOfBreaths ?? 0}
			/> */}
      </Grid>
    </Tooltip>
  );
};

export const EmotionTimeTypography = (props: { defaultToTime: boolean; thought: Thought }) => {
  const tags = props.thought.tags || [];
  const has_gratitude_tag = tags.includes('gratitude');
  const user_selected_emotions =
    props.thought.user_selected_emotions.length === 0
      ? has_gratitude_tag
        ? ['gratitude']
        : []
      : props.thought.user_selected_emotions;
  const created_at = props.thought.created_at;
  return (
    <Grid container item xs alignItems="center" width="100%" sx={{ cursor: 'default' }}>
      <TimeTypography defaultToTime={props.defaultToTime} created_at={created_at} />
      {user_selected_emotions.length > 0 ||
        (tags.length > 0 && (
          <Circle
            sx={{
              paddingLeft: '4px',
              paddingRight: '4px',
              height: '2px',
              width: '2px',
              color: '#A8A8A8',
            }}
          />
        ))}

      {has_gratitude_tag ? (
        <Icons.Heart
          style={{
            fontSize: '9px',
            padding: '0 4px',
            color: get_sentiment_color('harsh').color,
          }}
        />
      ) : (
        user_selected_emotions.length > 0 && (
          <AutoAwesomeRounded
            sx={{
              paddingLeft: '4px',
              paddingRight: '4px',
              height: '16px',
              width: '16px',
              color: '#E3E3E7',
            }}
          />
        )
      )}

      {user_selected_emotions.length > 0 && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: '18px',
            color: '#ADACB0',
            paddingLeft: '4px',
          }}
        >
          {user_selected_emotions.join(', ')}
        </Typography>
      )}
      {/* {has_gratitude_tag && 'gratitude, love'} */}
      {/* {tags.length > 0 && <Typography>#</Typography>} */}
      {tags.length > 0 && !has_gratitude_tag && (
        <Typography
          sx={{
            fontSize: '12px',
            lineHeight: '18px',
            color: '#ADACB0',
            paddingLeft: '4px',
          }}
        >
          #{tags.join(', ')}
        </Typography>
      )}
    </Grid>
  );
};

const TimeTypography = (props: { defaultToTime: boolean; created_at: string }) => {
  const [showFormattedTimeView, setShowFormattedTimeView] = useState(false);
  const nonDefaultTimeFn = props.defaultToTime ? getTimeDifference : formatTime;
  const defaultTimeFn = props.defaultToTime ? formatTime : getTimeDifference;

  return (
    <Typography
      onMouseEnter={() => {
        !props.defaultToTime && setShowFormattedTimeView(true);
      }}
      onMouseLeave={() => {
        !props.defaultToTime && setShowFormattedTimeView(false);
      }}
      sx={{
        // cursor: "default",
        fontFamily: 'arial',
        color: '#A8A8A8',
        fontSize: '12px',
        lineHeight: '20px',
        paddingRight: '8px',
      }}
    >
      {(showFormattedTimeView ? nonDefaultTimeFn : defaultTimeFn)(props.created_at)}
    </Typography>
  );
};
