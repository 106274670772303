import {
  AuthorizedState,
  NounCount,
  SageThought,
  SageThoughts,
  Thought,
  User,
} from "./Schema";

import { UUID } from "uuid";

// Authentication types
export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";
export const SET_PIN_TOKEN = "SET_PIN_TOKEN";
export const SET_AUTHORIZED = "SET_AUTHORIZED";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_AUTHORIZED_STATE = "SET_AUTHORIZED_STATE";

export const set_authorized_state = (state: AuthorizedState) => ({
  type: SET_AUTHORIZED_STATE,
  payload: { state },
});
export const setAuthorized = (isAuthorized: boolean) => ({
  type: SET_AUTHORIZED,
  payload: { isAuthorized },
});

export const setAuthenticated = (isAuthenticated: boolean) => ({
  type: SET_AUTHENTICATED,
  payload: { isAuthenticated },
});

export const setSessionToken = (token: string) => ({
  type: SET_SESSION_TOKEN,
  payload: { token },
});

export const setPinToken = (token: string) => ({
  type: SET_PIN_TOKEN,
  payload: { token },
});

// Pulse of Planet Types
export const ADD_BREATH_COUNT = "ADD_BREATH_COUNT";
export const update_breath_count = (count: number) => ({
  type: ADD_BREATH_COUNT,
  payload: { count },
});
export const SET_BREATH_COUNT = "SET_BREATH_COUNT";
export const set_breath_count = (count: number) => ({
  type: SET_BREATH_COUNT,
  payload: { count },
});

export const ADD_GRATITUDE = "ADD_GRATITUDE";
export const add_gratitude = (text: string) => ({
  type: ADD_GRATITUDE,
  payload: { text },
});
export const SET_GRATITUDES = "SET_GRATITUDES";
export const set_gratitudes = (gratitudes: string[]) => ({
  type: SET_GRATITUDES,
  payload: { gratitudes },
});

// Stats Types
export const FETCH_THOUGHT_NOUNS = "FETCH_THOUGHT_NOUNS";
export const FETCH_SENTIMENT_SCORE = "FETCH_SENTIMENT_SCORE";
export const GET_WEEKLY_GOLDEN_HOUR_STATS = "GET_WEEKLY_GOLDEN_HOUR_STATS";
export const GET_MONTHLY_GOLDEN_HOUR_STATS = "GET_MONTHLY_GOLDEN_HOUR_STATS";
export const GET_THOUGHT_COUNTS = "GET_THOUGHT_COUNTS";
export const GET_THOUGHT_NOUNS = "GET_THOUGHT_NOUNS";

export const fetchThoughtNouns = (nouns: NounCount) => ({
  type: FETCH_THOUGHT_NOUNS as typeof FETCH_THOUGHT_NOUNS,
  payload: { nouns },
});

export const fetchSentimentScore = (score: number) => ({
  type: FETCH_SENTIMENT_SCORE as typeof FETCH_SENTIMENT_SCORE,
  payload: { score },
});

export const getWeeklyGoldenHourStats = (weeklyGoldenHour: number[][]) => ({
  type: GET_WEEKLY_GOLDEN_HOUR_STATS as typeof GET_WEEKLY_GOLDEN_HOUR_STATS,
  payload: { weeklyGoldenHour },
});

export const getMonthlyGoldenHourStats = (monthlyGoldenHour: number[][]) => ({
  type: GET_MONTHLY_GOLDEN_HOUR_STATS as typeof GET_MONTHLY_GOLDEN_HOUR_STATS,
  payload: { monthlyGoldenHour },
});

export const getThoughtNouns = (
  type: string,
  positiveThoughtNouns: NounCount,
  neutralThoughtNouns: NounCount,
  negativeThoughtNouns: NounCount
) => ({
  type: GET_THOUGHT_NOUNS as typeof GET_THOUGHT_NOUNS,
  payload: {
    type,
    positiveThoughtNouns,
    neutralThoughtNouns,
    negativeThoughtNouns,
  },
});

export const getThoughtCounts = (
  type: string,
  numPositiveThoughts: number,
  numNeutralThoughts: number,
  numNegativeThoughts: number
) => ({
  type: GET_THOUGHT_COUNTS as typeof GET_THOUGHT_COUNTS,
  payload: {
    type,
    numPositiveThoughts,
    numNeutralThoughts,
    numNegativeThoughts,
  },
});

// Config Types
export const MODIFY_TIMER_DURATION = "MODIFY_TIMER_DURATION";
export const modifyTimerDuration = (duration: number) => ({
  type: MODIFY_TIMER_DURATION,
  payload: { duration },
});

//Thought Types
export const ADD_THOUGHT = "ADD_THOUGHT";
export const ADD_THOUGHT_SENTIMENT = "ADD_THOUGHT_SENTIMENT";
export const UPDATE_SAGE_THOUGHT = "UPDATE_SAGE_THOUGHT";
export const SELECT_THOUGHT_EMOTIONS = "SELECT_THOUGHT_EMOTIONS";
export const REMOVE_THOUGHT = "REMOVE_THOUGHT";
export const REMOVE_THOUGHT_EMOTIONS = "REMOVE_THOUGHT_EMOTIONS";
export const SET_CURRENT_MONTH_THOUGHTS = "SET_CURRENT_MONTH_THOUGHTS";

export const addThought = (thought: Thought) => ({
  type: ADD_THOUGHT,
  payload: { thought },
});

export const update_sage_thought = (
  thought_uuid: UUID,
  reframed_thought: string
) => ({
  type: UPDATE_SAGE_THOUGHT,
  payload: { thought_uuid, reframed_thought },
});

export const addThoughtSentiment = (
  uuid: UUID,
  sentiment: string,
  computed_top_emotions: string[]
) => ({
  type: ADD_THOUGHT_SENTIMENT,
  payload: { uuid, sentiment, computed_top_emotions },
});

export const selectThoughtEmotions = (
  uuid: UUID,
  user_selected_emotions: string[],
  sentiment: string
) => ({
  type: SELECT_THOUGHT_EMOTIONS,
  payload: { uuid, user_selected_emotions, sentiment },
});

export const removeThoughtEmotions = (uuid: UUID) => ({
  type: REMOVE_THOUGHT_EMOTIONS,
  payload: { uuid },
});

export const removeThought = () => ({
  type: REMOVE_THOUGHT,
  payload: {},
});
export const set_all_thoughts = (thoughts: Thought[]) => ({
  type: SET_CURRENT_MONTH_THOUGHTS as typeof SET_CURRENT_MONTH_THOUGHTS,
  payload: { thoughts },
});

export const set_curent_month_sage_thoughts = (thoughts: Thought[]) => ({
  type: SET_CURRENT_MONTH_THOUGHTS as typeof SET_CURRENT_MONTH_THOUGHTS,
  payload: { thoughts },
});
// export const get_thoughts_middleware = (thoughts: Thought[]) => ({

// })
export const CREATE_SAGE_THOUGHT = "CREATE_SAGE_THOUGHT";
export const create_sage_thought_middleware = (
  thought_uuid: UUID,
  sage_thought: SageThought
) => ({
  type: CREATE_SAGE_THOUGHT,
  payload: { thought_uuid: thought_uuid, sage_thought: sage_thought },
});

export const SET_SAGE_THOUGHTS = "SET_SAGE_THOUGHTS";
export const set_sage_thoughts = (sage_thoughts: SageThoughts) => ({
  type: SET_SAGE_THOUGHTS,
  payload: { sage_thoughts: sage_thoughts },
});

// User Types
export const FETCH_USER = "FETCH_USER";
export const fetchUser = (user: User) => ({
  type: FETCH_USER,
  payload: { user },
});
