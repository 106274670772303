import { Grid, Modal, Typography } from '@mui/material';
import { get_beige, get_cta_color, get_dark_blue, get_sentiment_color } from '../Util/Helper';

import mascot_holding_star from '../assets/images/logo/mascot_holding_star.svg';
import mascot_meditating from '../assets/images/logo/mascot_meditating.svg';
import gradient_circle from '../assets/images/svg-files/gradient_circle.svg';
import top_left_shape from '../assets/images/svg-files/top_left_shape.svg';

export default function QuestModal(props: { open: boolean; setOpen: (open: boolean) => void }) {
  const is_sunday = true; //new Date().getDay() === 0;
  // const view = is_sunday ? <SageReport /> : <CheckBackLater />;
  const view = <CheckBackLater />;
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
    >
      <Grid
        container
        sx={{
          height: '720px',
          width: '1000px',
        }}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowX: 'hidden',
          overflowY: 'hidden',
        }}
        direction="column"
      >
        <Grid
          container
          item
          xs
          sx={{
            background: 'linear-gradient(145deg, #0f2636, #0c1a29)',
            borderRadius: '16px',
            padding: '24px',
            overflowY: 'auto',
          }}
        >
          {view}
        </Grid>
      </Grid>
    </Modal>
  );
}

const CheckBackLater = () => {
  return (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: get_beige(),
        borderRadius: '8px',
        height: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        overflowY: 'auto',
        flexWrap: 'nowrap',
      }}
    >
      <Grid
        container
        item
        xs
        position="absolute"
        top="50%"
        left="50%"
        borderRadius="50%"
        width="420px"
        height="420px"
        sx={{
          transform: 'translate(-50%, -50%)',
          // background:
          // 	'linear-gradient(90deg, #A6D5C1, #FDDEA5, #FFA6A5)'
        }}
      >
        <img src={gradient_circle} />
      </Grid>
      <Grid container item xs position="absolute" bottom="24px" left="24px">
        <img src={mascot_holding_star} style={{ height: '210px' }} />
      </Grid>
      {/* <Grid
				container
				item
				xs
				position="absolute"
				bottom="42px"
				right="36px"
				width="120px"
			>
				<img src={bottom_right_rocket} style={{ height: '120px' }} />
			</Grid> */}
      <Grid
        container
        item
        xs
        position="absolute"
        bottom="24px"
        right="24px"
        width="120px"
        sx={{ rotate: '180deg' }}
      >
        <img src={top_left_shape} style={{ height: '240px' }} />
      </Grid>
      <Grid container item xs position="absolute" top="24px" left="24px">
        <img src={top_left_shape} style={{ height: '240px' }} />
      </Grid>

      <Grid container item xs position="absolute" top="42px" right="42px" width="180px">
        <img src={mascot_meditating} style={{ height: '180px' }} />
      </Grid>
      <Grid
        container
        item
        position="absolute"
        // width="140px"
        top="80px"
        height="40px"
        sx={{
          //   backgroundColor: "white",
          borderRadius: '24px',
          //   padding: "8px",
        }}
        justifyContent="center"
      >
        <Typography
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            color: get_dark_blue(),
            fontSize: '30px',
            fontWeight: 800,
            background: `linear-gradient(45deg, ${get_sentiment_color('harsh').color} 30%, ${
              get_sentiment_color('sage').color
            } 60%, ${get_cta_color()} 90%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            display: 'inline',
          }}
        >
          Sage Day Report
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        zIndex={3000}
      >
        <Grid container item xs justifyContent="center">
          <Typography
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              color: get_dark_blue(),
              fontSize: '18px',
              fontFamily: 'arial',
              // fontWeight: 600
            }}
          >
            Big things are coming... <br />
            <br />
            {/* for deeper insights into your mind. */}
          </Typography>
        </Grid>
        {/* <Grid container item xs> */}
        <Grid container item xs justifyContent="center" alignItems="center" marginTop="-8px">
          <Typography
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              color: get_dark_blue(),
              fontSize: '13px',
              fontFamily: 'arial',

              // fontWeight: 600
            }}
          >
            Stay tuned for deeper insights into your mind and personality.
          </Typography>
        </Grid>

        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};
