export const centeredGridStyles = {
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: 'white',
	border: '1px solid #F1F1F1'
};

export const dash_grid_styles = {
	backgroundColor: 'white',
	alignItems: 'center',
	justifyContent: 'flex-start',
	alignContent: 'flex-start'
};

export const dash_title_typography_styles = {
	cursor: 'default',
	fontSize: '16px'
	// lineHeight: '24px'
	// fontWeight: 600
	//  '#345247'
};
