import { UUID } from 'uuid';
import store from './Store';
export type AppDispatch = typeof store.dispatch;

export interface QuestionId {
	questionId: number;
	questionText: string;
}

export interface ReframedThought {
	thought: string;
	created_at: string;
	uuid: UUID;
}

export interface Thought {
	thought: string;
	uuid: UUID;
	user_selected_emotions: string[];
	created_at: string; // utc time
	sentiment: 'positive' | 'negative' | 'neutral' | 'unknown';
	computed_top_emotions: string[];
	reframed: string;
	tags: string[];
	// parentThoughtUuid: UUID | null;
}

export interface Bias {
	name: string;
	reason: string;
}

export interface GuidingQuestions {
	question: string;
	reasoning: string;
}

export interface SageThought {
	uuid: UUID;
	empathy: string;
	//   biases: Bias[];
	guiding_questions: GuidingQuestions[];
	reframed_thought: string;
	healthy_suggestions: string;
	user_reframed_thought: string;
	source_thought_id: UUID;
	created_at: string;
}

export interface SageThoughts {
	[key: string]: SageThought;
}

export interface Thoughts {
	all_thoughts: Thought[];
}

export type NounCount = { noun: string; occurrences: number };
export type Nouns = {
	nouns: string[];
	noun_chunks: string[];
};

export type SentimentThoughtCounts = {
	positive: number;
	negative: number;
	neutral: number;
};

export interface Stats {
	sentimentScore: number;
	weeklyWellbeing: WellbeingCompletion[];
	weeklyFetched: boolean;
	monthlyFetched: boolean;
	monthlyWellbeing: WellbeingCompletion[];
	weeklyThoughtNouns: SentimentNounCounts | null;
	monthlyThoughtNouns: SentimentNounCounts | null;
	allTimeThoughtNouns: SentimentNounCounts | null;
	allTimeThoughtCounts: SentimentThoughtCounts | null;
	weeklyThoughtSentiment: {
		positive: number;
		negative: number;
		neutral: number;
	};
}

export interface User {
	// userId: number;
	firstName: string;
	// lastName: string;
	// email: string;
}

export interface Question {
	questionId: number;
	questionText: string;
}

export interface QuestionResponse {
	questionId: number;
	response: string;
	updateTimestamp: number;
}

export interface Response {
	// question: string;
	response: string;
	updateTimestamp: string;
}

export interface Checkin {
	checkinId: number;
	checkinType: 'morning' | 'evening';
	userId: number;
	timestamp: number; // Date.now()
	questionResponses: QuestionResponse[];
}

export interface Gratitude {
	id: number;
	response: string;
	updateTimestamp: string;
}

export interface Task {
	taskId: number;
	taskName: string;
}

export interface Intention {
	id: number;
	text: string;
	completed: boolean;
	editable: boolean; // for editing
	completedTimestamp: string | null;
	textUpdatedTimestamp: string | null;
}

export interface Priority {
	task: string;
	completed: boolean;
	completedTimestamp: string | null;
	taskUpdatedTimestamp: string;
}

export interface TaskResult {
	taskId: number;
	completed: boolean;
	updateTimestamp: number;
}

export interface WellbeingCompletion {
	mind: boolean;
	body: boolean;
	spirit: boolean;
}

export interface Spirit {
	setsCompleted: number;
	completed: boolean;
}

export interface Body {
	activities: string[];
	completed: boolean;
}

export interface Mind {
	gratitudes: string[];
	completed: boolean;
}

export interface List {
	id: number;
	name: string;
	tasks: Task[];
}

export type AuthorizedState =
	| 'authorized'
	| 'user_not_found'
	| 'incorrect_pin'
	| 'unauthorized';

export interface Auth {
	sessionToken: string | null;
	authorized_state: AuthorizedState;
	pinToken: string | null;
	isAuthenticated: boolean | null;
	isAuthorized: boolean;
}

export interface Config {
	timerDuration: number;
}

export interface PulseOfPlanet {
	saged: number;
	joyful: number;
	breath_count: number;
	gratitudes: string[];
}

export interface RootState {
	user: User;
	auth: Auth;
	config: Config;
	pulseOfPlanet: PulseOfPlanet;
	thoughts: Thoughts;
	sageThoughts: SageThoughts;
	stats: Stats;
}

export const initialState: RootState = {
	user: {
		firstName: ''
	},
	config: {
		timerDuration: 20
	},
	auth: {
		sessionToken: null,
		pinToken: null,
		authorized_state: null,
		isAuthenticated: null,
		isAuthorized: false
	},
	pulseOfPlanet: {
		saged: 100,
		joyful: 100,
		breath_count: 0,
		gratitudes: []
	},
	stats: {
		nouns: null,
		sentimentScore: 0,
		weeklyWellbeing: [],
		monthlyWellbeing: [],
		weeklyFetched: false,
		monthlyFetched: false,
		weeklyThoughtNouns: null,
		monthlyThoughtNouns: null,
		allTimeThoughtNouns: null,
		allTimeThoughtCounts: null,
		weeklyThoughtSentiment: {
			positive: 0,
			negative: 0,
			neutral: 0
		}
	},
	thoughts: {
		all_thoughts: []
	},
	sageThoughts: {}
};
