import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import BaseCard from '../../../components/molecules/BaseCard';
import CardHeader from '../../../components/molecules/CardHeader';
import DailyQuestCard from '../../../components/organisms/DailyQuestCard';
import { DailyStat } from '../../../components/organisms/DailyStatsList';
import { Icons } from '../../../components/organisms/Icons';
import { get_sentiment_color, get_sky_blue, parse_daily_gratitudes } from '../../../Util/Helper';
import { RootState } from '../../../Util/Redux/Schema';

export const get_quest_stats = (gratitudes: string[], breath_count: number): DailyStat[] => {
  return [
    // {
    // 	text: 'Enter Thoughts',
    // 	icon: (
    // 		<Grid
    // 			container
    // 			item
    // 			sx={{
    // 				backgroundColor: get_light_gray()
    // 			}}
    // 			borderRadius="8px"
    // 			marginRight="8px"
    // 			padding="8px"
    // 			justifyContent="center"
    // 			alignItems="center"
    // 		>
    // 			<Icons.Cloud
    // 				height="32px"
    // 				style={{ fontSize: 24, color: getDarkGray() }}
    // 			/>
    // 		</Grid>
    // 	),
    // 	value: Math.min(gratitudes.length, 3)
    // },
    {
      text: 'Enter Gratitudes',
      icon: (
        <Grid
          container
          item
          sx={{
            backgroundColor: get_sentiment_color('harsh').backgroundColor,
          }}
          borderRadius="8px"
          marginRight="8px"
          padding="8px"
          justifyContent="center"
          alignItems="center"
        >
          <Icons.Heart height="32px" style={{ fontSize: 24 }} />
        </Grid>
      ),
      value: Math.min(gratitudes.length, 3),
      max_count: 3,
    },
    {
      text: 'Take Deep Breaths',
      icon: (
        <Grid
          container
          item
          sx={{
            backgroundColor: get_sentiment_color('sage').backgroundColor,
          }}
          borderRadius="8px"
          marginRight="8px"
          padding="8px"
          justifyContent="center"
          alignItems="center"
        >
          <Icons.Breathe style={{ fontSize: 24 }} />
        </Grid>
      ),
      value: Math.min(breath_count, 1),
      max_count: 1,
    },
  ];
};

export const QuestContent = (props: { date_range: 'Day' | 'Week' | 'Month' }) => {
  const gratitudes = useSelector((state: RootState) =>
    parse_daily_gratitudes(state.thoughts.all_thoughts),
  );
  const breath_count = useSelector((state: RootState) => state.pulseOfPlanet.breath_count);
  const key_stats = get_quest_stats(gratitudes, breath_count);

  return (
    <BaseCard
      sx={{
        height: '100%',
        border: `2px solid ${get_sky_blue()}`,
        backgroundColor: 'white',
        // backgroundImage:
        //   "linear-gradient(145deg, rgb(255, 244, 225, 0.5), rgb(217, 240, 231, 0.5), rgb(255, 227, 227, 0.5))"
      }}
    >
      <CardHeader title="Daily Quests" />
      {key_stats.map((stat, idx) => (
        <Grid container item xs key={idx} marginTop="12px">
          <DailyQuestCard
            title={stat.text}
            icon={stat.icon}
            value={stat.value}
            max_count={stat.max_count}
          />
        </Grid>
      ))}
    </BaseCard>
  );
};

export default QuestContent;
