import { Grid, Typography } from '@mui/material';
import { get_dark_blue, get_text_color } from '../Util/Helper';

import React from 'react';

const ErrorPage: React.FC = () => (
	<Grid
		container
		direction="column"
		justifyContent="center"
		alignItems="center"
		sx={{
			height: '100vh',
			width: '100vw',
			backgroundImage:
				'linear-gradient(145deg, #FFF4E1, #D9F0E7, #FFE3E3)'
		}}
	>
		<Grid
			container
			item
			direction="column"
			sx={{
				backgroundColor: 'white',
				borderRadius: '16px',
				width: '720px',
				padding: '32px'
			}}
		>
			<Typography
				sx={{
					fontSize: '32px',
					fontWeight: 600,
					fontFamily: 'arial',
					color: get_text_color(),
					marginBottom: '16px'
				}}
			>
				500 Server Error
			</Typography>
			<Typography
				sx={{
					fontSize: '24px',
					fontWeight: 500,
					fontFamily: 'arial',
					color: get_text_color(),
					marginBottom: '16px'
				}}
			>
				It's not you, it's us.
			</Typography>
			<Typography
				sx={{
					fontSize: '16px',
					fontFamily: 'arial',
					color: get_dark_blue()
				}}
			>
				Something went wrong on our end. Get in touch with our support
				team, and we'll get it fixed right away for you.
			</Typography>
		</Grid>
	</Grid>
);

export default ErrorPage;
