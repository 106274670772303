import { Grid } from '@mui/material';
import {
	get_sentiment_color,
	getDarkGreen,
	SubmitButton
} from '../../../Util/Helper';

interface TimeSelectionButtonsProps {
	selected_duration: number;
	set_selected_duration: (button: number) => void;
}

export default function TimeSelectionButtons({
	selected_duration,
	set_selected_duration
}: TimeSelectionButtonsProps) {
	return (
		<Grid
			container
			item
			xs
			width="100%"
			justifyContent="space-between"
			alignItems="flex-start"
			marginTop="16px"
		>
			{[0, 1, 2].map((buttonIndex) => (
				<span key={buttonIndex}>
					<SubmitButton
						marginTop="0px"
						textColor={getDarkGreen()}
						backgroundColor={'white'}
						border={
							selected_duration === buttonIndex * 2 + 1
								? '4px solid ' +
								  get_sentiment_color('sage').color
								: undefined
						}
						textTransform="none"
						text={`${buttonIndex * 2 + 1} minute`}
						height="80px"
						width="160px"
						onClick={() =>
							set_selected_duration(buttonIndex * 2 + 1)
						}
					/>
				</span>
			))}
		</Grid>
	);
}
