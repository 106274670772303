import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Icons } from '../../../components/organisms/Icons';
import { get_text_color } from '../../../Util/Helper';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  borderRadius: '24px',
  textTransform: 'none',
  boxShadow: 'none',
  padding: theme.spacing(1),
  paddingRight: theme.spacing(2),
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
    boxShadow: 'none'
  }
}));

interface GeneratePromptButtonProps {
  onClick: () => void;
}

const GeneratePromptButton: React.FC<GeneratePromptButtonProps> = ({ onClick }) => {
  return (
    <Grid container item>
    <CustomButton onClick={onClick}>
      <Icons.Create style={{ marginRight: '4px', fontSize: '16px' }} />
      <Typography style={{color: get_text_color(), fontSize: '12px', fontFamily: 'arial'}}>
        Get a prompt
      </Typography>
    </CustomButton>
    </Grid>
  );
};

export default GeneratePromptButton; 