import { Grid, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { getTimestamp } from '../../Util/Helper';
import { post_thought } from '../../Util/Redux/AsyncMiddleware';
import { addThought } from '../../Util/Redux/Middleware';
import { AppDispatch } from '../../Util/Redux/Schema';

const GratitudeBar = (props: { placeholder: string }) => {
	const [message, setMessage] = useState(
		localStorage.getItem('gratitudeBarMessage') || ''
	);
	const dispatch = useDispatch<AppDispatch>();
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMessage(event.target.value);
		localStorage.setItem('gratitudeBarMessage', event.target.value);
	};
	const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent Line Break
			if (message.length > 0) {
				dispatch(
					addThought({
						created_at: getTimestamp(),
						sentiment: 'unknown',
						thought: message,
						computed_top_emotions: [],
						uuid: null,
						user_selected_emotions: [],
						reframed: '',
						tags: ['gratitude']
					})
				);
				dispatch(post_thought(message, ['gratitude']));
				setMessage('');
				localStorage.removeItem('gratitudeBarMessage');
			}
		}
	};

	return (
		<Grid
			container
			item
			justifyContent="space-between"
			width="100%"
			alignItems="center"
			sx={{
				marginTop: '8px',
				backgroundColor: '#F8F8F8',
				borderRadius: '32px',
				padding: '8px 16px'
			}}
		>
			<Grid container item xs>
				<TextareaAutosize
					placeholder={props.placeholder}
					value={message}
					maxRows={2}
					style={{
						fontFamily: 'arial',
						color: '#0A090B',
						fontWeight: 450,
						backgroundColor: '#F8F8F8',
						fontSize: '14px',
						lineHeight: '20px',
						letterSpacing: '-0.05px',
						width: '100%',
						border: 'none',
						outline: 'none',
						resize: 'none',
						minHeight: '32px',
						paddingTop: '12px'
					}}
					onChange={handleChange}
					onKeyDown={handleKeyPress}
				/>
			</Grid>
		</Grid>
	);
};

export default GratitudeBar;
