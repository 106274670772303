import { AnyAction, Dispatch } from 'redux';
import {
	addThoughtSentiment,
	create_sage_thought_middleware,
	removeThought,
	setAuthenticated,
	setAuthorized,
	setSessionToken,
	set_all_thoughts,
	set_authorized_state,
	set_breath_count,
	set_gratitudes,
	set_sage_thoughts,
	update_breath_count,
	update_sage_thought
} from './Middleware';
import { AppDispatch, RootState } from './Schema';

import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { ThunkAction } from 'redux-thunk';
import { UUID } from 'uuid';
import { DateRange } from '../Helper';
import axios from '../axiosConfig';

export const post_thought = (
	message: string,
	tags: string[]
): ThunkAction<void, RootState, null, AnyAction> => {
	return async (dispatch: Dispatch) => {
		axios
			.post(
				'/api/create/thought',
				{
					text: message,
					tags: tags
				},
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(function (response) {
				dispatch(
					addThoughtSentiment(
						response.data.uuid,
						'',
						response.data.computed_top_emotions
					)
				);
			})
			.catch(function (error) {
				// console.log('Error posting thought: ', error);
				dispatch(removeThought());
			});
	};
};

export const fetch_breath_count = (date_range: DateRange) => {
	const start_date = get_start_date(date_range);
	console.log('Start Date', start_date);
	return async (dispatch: Dispatch) => {
		axios
			.get('/api/mindfulness/breath', {
				params: { start_date: start_date }
			})
			.then(function (response) {
				dispatch(set_breath_count(response.data.zenful_minutes || 0));
			});
	};
};
export const post_breath_count = (count: number) => {
	return async (dispatch: Dispatch) => {
		axios.post('/api/mindfulness/breath', {
			breath_count: count
		});
		dispatch(update_breath_count(count));
	};
};

export const fetch_gratitudes = (date_range: DateRange) => {
	const start_date = get_start_date(date_range);
	return async (dispatch: Dispatch) => {
		axios
			.get('/api/mindfulness/gratitudes', {
				params: { start_date: start_date }
			})
			.then(function (response) {
				dispatch(set_gratitudes(response.data));
			});
	};
};

export const update_user_reframed_thought =
	(reframed_thought: string, uuid: UUID, thought_uuid: UUID) =>
	async (dispatch: Dispatch) => {
		try {
			axios.post('/api/update/sage-thought', {
				reframed_thought: reframed_thought,
				uuid: uuid
			});
			dispatch(update_sage_thought(thought_uuid, reframed_thought));
			// console.log('Successfully updated sage thought');
		} catch (error) {
			console.error('Error in sageThoughtsReducer:', error);
		}
	};

function get_iso_date(days_ago: number): string {
	const today = new Date();
	today.setDate(today.getDate() - Math.abs(days_ago));
	return today.toISOString();
}
// d.setDate(d.getDate() - Math.abs(n));

export const fetch_sage_thoughts =
	(days_from_today: number) => async (dispatch: AppDispatch) => {
		const start_date = get_iso_date(days_from_today);
		await axios
			.get('/api/sage-thoughts', {
				params: { start_date: start_date }
			})
			.then(function (response) {
				dispatch(set_sage_thoughts(cloneDeep(response.data)));
			});
	};

export const create_sage_thought =
	(thought_uuid: UUID) => async (dispatch: AppDispatch) => {
		axios
			.post('/api/create/sage-thought', { thought_uuid: thought_uuid })
			.then(function (response) {
				dispatch(
					create_sage_thought_middleware(thought_uuid, {
						user_reframed_thought: '',
						uuid: response.data.uuid,
						empathy: response.data.empathy,
						guiding_questions: response.data.guiding_questions,
						// challenge: response.data.challenge,
						created_at: response.data.created_at,
						reframed_thought: response.data.reframed_thought,
						healthy_suggestions: response.data.healthy_suggestions,
						source_thought_id: null
					})
				);
			})
			.catch(function (error) {
				// console.log('Error fetching sage thought: ', error);
			});
	};

export const postSearch = async (search: string) => {
	return axios
		.post(
			'/api/thoughts/search',
			{
				query: search
			},
			{ headers: { 'Content-Type': 'application/json' } }
		)
		.then(function (response) {
			return response.data.thoughts;
		})
		.catch(function (error) {
			// console.log('Error posting thought: ', error);
			// dispatch(removeThought());
		});
};

// const xyz = () => async (dispatch) => {

export const postEmotions = async (thoughtId: UUID, emotions: string[]) => {
	try {
		const response = await axios.post(
			'/api/thought/emotions',
			{
				thoughtId: thoughtId,
				emotions: emotions
			},
			{ headers: { 'Content-Type': 'application/json' } }
		);

		return response; // Ensure you return the sentiment here
	} catch (error) {
		return null;
	}
};
export const fetch_thought_nouns = async () => {
	return await axios
		.get('/api/thought/nouns')
		.then((resp) => {
			return resp.data;
		})
		.catch((e) => 'Failed');
};
export const login =
	(username: string, password: string) => async (dispatch: AppDispatch) => {
		axios
			.post(
				'/login',
				{
					username: username,
					password: password
				},
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(function (response) {
				dispatch(setSessionToken(response.data.token));
				dispatch(setAuthenticated(true));
				axios.defaults.headers['Authorization'] =
					`Bearer ${response.data.token}`;
			})
			.catch(function (error) {});
	};

export const is_authorized =
	(email: string) => async (dispatch: AppDispatch) => {
		return await axios
			.get('/is_authorized?email=' + email)
			.then(function (response) {
				dispatch(set_authorized_state('authorized'));
				return true;
			})
			.catch(function (error) {
				if (error.response.status === 403) {
					sessionStorage.setItem(
						'authorized_state',
						'user_not_found'
					);
					dispatch(set_authorized_state('user_not_found'));
					return false;
				}
			});
	};

export const enter_with_pin =
	(pin: string) => async (dispatch: AppDispatch) => {
		await axios
			.post(
				'/api/pinLogin',
				{
					pin: pin
				},
				{ headers: { 'Content-Type': 'application/json' } }
			)
			.then(function (response) {
				sessionStorage.setItem('authorized_state', 'authorized');
				dispatch(setAuthorized(true));
				dispatch(set_authorized_state('authorized'));
			})
			.catch(function (error) {
				if (error.response.status === 403) {
					sessionStorage.setItem('authorized_state', 'incorrect_pin');
					dispatch(set_authorized_state('incorrect_pin'));

					dispatch(setAuthorized(false));
				} else if (error.response.status === 401) {
					sessionStorage.setItem(
						'authorized_state',
						'user_not_found'
					);
					dispatch(set_authorized_state('user_not_found'));
				} else {
					sessionStorage.setItem(
						'authorized_state',
						'user_not_found'
					);
					dispatch(set_authorized_state('user_not_found'));
				}
			});
	};

export const setBearerTokenToAxios = () => {
	const token = sessionStorage.getItem('bearerToken');
	axios.defaults.headers['Authorization'] = `Bearer ${token}`;
	axios.defaults.headers['TimezoneInfo'] =
		Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const get_start_date = (date_range: 'Day' | 'Week' | 'Month') => {
	if (date_range === 'Day') {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const now = new Date();

		// Get the current date in local timezone
		const localDate = new Date(
			now.toLocaleString('en-US', { timeZone: timezone })
		);

		// Set time to midnight in local timezone
		localDate.setHours(0, 0, 0, 0);

		// Convert to ISO string in UTC
		const startOfToday = new Date(localDate).toISOString();
		const nowIso = now.toISOString();

		return startOfToday;
	} else if (date_range === 'Month') {
		const first_day_of_month = new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			1
		);
		return convertToTimezoneMidnightIsoString(
			first_day_of_month,
			Intl.DateTimeFormat().resolvedOptions().timeZone
		);
	}
};

export const fetch_thoughts = () => async (dispatch: AppDispatch) => {
	const start_date = get_iso_date(30);
	await axios
		.get('/api/thoughts', {
			params: {
				start_date: start_date
			}
		})
		.then(function (response) {
			dispatch(set_all_thoughts(cloneDeep(response.data.thoughts)));
		});
};

// export const fetch_all_thoughts = () => async (dispatch: AppDispatch) => {
//   const start_date = get_iso_date(30);
//   await axios
//     .get("/api/thoughts", {
//       params: {
//         start_date: start_date,
//       },
//     })
//     .then(function (response) {
//       dispatch(set_all_thoughts(cloneDeep(response.data.thoughts)));
//     });
// };

export const fetch_current_month_thoughts =
	() => async (dispatch: AppDispatch) => {
		const first_day_of_month = new Date(
			new Date().getFullYear(),
			new Date().getMonth(),
			1
		);
		const start_date = convertToTimezoneMidnightIsoString(
			first_day_of_month,
			Intl.DateTimeFormat().resolvedOptions().timeZone
		);
		await axios
			.get('/api/thoughts', {
				params: {
					start_date: start_date
				}
			})
			.then(function (response) {
				dispatch(set_all_thoughts(cloneDeep(response.data.thoughts)));
			});
	};

export function convertToTimezoneMidnightIsoString(
	date: any,
	timezone: string
) {
	const momentDate = moment(date).tz(timezone);
	const midnight = momentDate.startOf('day');
	return midnight.toISOString();
}

export function convert_to_iso_date(date: any, timezone: string) {
	const momentDate = moment(date).tz(timezone);
	const midnight = momentDate.startOf('day');
	return midnight.toDate();
}

export const get_thought_entries = async (month: string) => {
	function get_last_day_of_month(year: number, month: number) {
		return new Date(year, month + 1, 0).getDate();
	}
	const monthIndex = {
		Jan: 0,
		Feb: 1,
		Mar: 2,
		Apr: 3,
		May: 4,
		Jun: 5,
		Jul: 6,
		Aug: 7,
		Sep: 8,
		Oct: 9,
		Nov: 10,
		Dec: 11
	}[month];
	if (monthIndex === undefined) {
		throw `Enter valid month ${month}`;
	}
	const this_year = new Date().getFullYear();
	const first_day_of_month = new Date(this_year, monthIndex, 1);
	const last_day_of_month = new Date(
		this_year,
		monthIndex,
		get_last_day_of_month(this_year, monthIndex)
	);
	const start_date = convertToTimezoneMidnightIsoString(
		first_day_of_month,
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	const end_date = convertToTimezoneMidnightIsoString(
		last_day_of_month,
		Intl.DateTimeFormat().resolvedOptions().timeZone
	);
	setBearerTokenToAxios();
	const data = axios
		.get('/api/thoughts', {
			params: {
				start_date: start_date,
				end_date: end_date
			}
		})
		.then(function (response) {
			// console.log('Return', response.data.thoughts);
			return cloneDeep(response.data.thoughts);
		})
		.catch((err) => {});
	return data;
	// return response.data.thoughts;
};
