import { Grid, Typography } from '@mui/material';
import React from 'react';
import { get_text_color } from '../../Util/Helper';

interface TitleWithIconProps {
  title: string;
  icon: React.ReactNode;
}

const TitleWithIcon: React.FC<TitleWithIconProps> = ({ title, icon }) => {
  return (
    <Grid
      container
      item
      alignItems="center"
      // marginBottom="8px"
      sx={{ cursor: 'default' }}
    >
      {icon}
      <Typography
        sx={{
          color: get_text_color(),
          fontSize: '12px',
          marginLeft: '8px',
          fontFamily: 'arial'
        }}
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default TitleWithIcon; 