import { Grid, IconButton, Typography } from '@mui/material';
import { createStyles, Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { CloseRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { Decoration } from '../../components/molecules/DecoratedModal';
import SageType from '../../typography/SageType';
import { get_cta_color, getDarkGreen } from '../../Util/Helper';
import { AppDispatch } from '../../Util/Redux/Schema';

function CloseButton({ onClose }: { onClose?: () => void }) {
	return (
		<Grid container item xs={1} justifyContent="flex-end" padding="16px">
			<IconButton
				onClick={onClose}
				sx={{
					color: getDarkGreen(),
					'&:hover': { backgroundColor: 'transparent' }
				}}
			>
				<CloseRounded style={{ fontSize: '24px' }} />
			</IconButton>
		</Grid>
	);
}

interface UserBreathSelectionProps {
	current_round: number;
	total_rounds: number;
}
function UserBreathSelection({
	current_round,
	total_rounds
}: UserBreathSelectionProps) {
	return (
		<Grid container item justifyContent="center">
			<SageType
				sx={{
					fontSize: '20px',
					lineHeight: '24px',
					color: getDarkGreen()
				}}
			>
				{`Round: ${current_round || 1}/${total_rounds}`}
			</SageType>
		</Grid>
	);
}

function SmallScreenTimer({ timer }: { timer: number | null }) {
	const roundNumber = timer !== null ? 5 - Math.ceil(timer / 12) + 1 : 0;
	let displayText = '';
	if (timer === null) {
		displayText = 'One Minute Zen';
	} else if (timer === 60) {
		displayText = `Round ${roundNumber}/5 | 1:00`;
	} else {
		displayText = `Round ${roundNumber}/5 | 0:${timer
			.toString()
			.padStart(2, '0')}`;
	}
	return (
		<Typography
			sx={{
				fontSize: '12px',
				fontFamily: 'arial',
				color: getDarkGreen(),
				marginBottom: '8px',
				fontWeight: 'bold'
			}}
		>
			{displayText + 'm'}
		</Typography>
	);
}

function BreathControlSection({
	size,
	current_round,
	set_current_round,
	total_rounds,
	onClose
}: {
	size: string;
	current_round: number;
	set_current_round: React.Dispatch<React.SetStateAction<number>>;
	total_rounds: number;
	onClose?: () => void;
}) {
	return (
		<Grid
			container
			item
			xs="auto"
			direction="column"
			justifyContent="center"
			alignItems="center"
		>
			{size !== 'small' && (
				<UserBreathSelection
					current_round={current_round}
					total_rounds={total_rounds}
				/>
			)}
			<span>
				<BreathingCircle
					current_round={current_round}
					set_current_round={(count) => {
						if (count === null) {
							set_current_round(0);
							return;
						}
						set_current_round(count);
					}}
					total_rounds={total_rounds}
					size={size}
					onClose={onClose}
				/>
			</span>
		</Grid>
	);
}

export default function BreathCircle(props: {
	size: string;
	onClose?: () => void;
	decorations?: Decoration[];
	total_rounds?: number;
}) {
	const [current_round, set_current_round] = useState(0);
	const [showCompletion, setShowCompletion] = useState(false);
	const total_rounds = props.total_rounds || 3;

	useEffect(() => {
		if (current_round === total_rounds + 1) {
			props?.onClose?.();
		}
	}, [current_round, total_rounds]);

	return (
		<Grid
			container
			item
			xs
			direction="column"
			// sx={{ position: 'relative' }}
		>
			{/* {props.size === 'large' && <CloseButton onClose={props.onClose} />} */}
			{/* {props.size === 'small' && <SmallScreenTimer timer={timer} />} */}

			<BreathControlSection
				size={props.size}
				current_round={current_round}
				set_current_round={set_current_round}
				total_rounds={total_rounds}
				onClose={props.onClose}
			/>
		</Grid>
	);
}

// const OUTER_COLOR = '#E9EBF8';
// const INNER_COLOR = '#C2D0F1';

// const OUTER_COLOR = get_cta_color(0.3);
// const INNER_COLOR = get_cta_color(0.5);
// const CENTER_COLOR = '#BEB6D0';
// get_cta_color(0.5);

const OUTER_COLOR = '#E8EBF8';
const INNER_COLOR = '#C2D0F3';
const CENTER_COLOR = '#C2D0F3';
// '#8D9BB6';

export const use_breath_circle_styles = makeStyles((theme: Theme) =>
	createStyles({
		inhale: {
			animation: '$inhaleAnimation 5s ease-in-out forwards',
			animationPlayState: (props: { isPlaying: boolean | null }) =>
				props.isPlaying ? 'running' : 'paused'
		},

		exhale: {
			animation: '$exhaleAnimation 5s ease-in-out forwards',
			animationPlayState: (props: { isPlaying: boolean | null }) =>
				props.isPlaying ? 'running' : 'paused'
		},

		'@keyframes inhaleAnimation': {
			'0%': {
				transform: 'scale(.50)'
			},
			'100%': {
				transform: 'scale(1)'
			}
		},
		'@keyframes exhaleAnimation': {
			'0%': {
				transform: 'scale(1)'
			},
			'100%': {
				transform: 'scale(.50)'
			}
		}
	})
);

export const BreathingCircle = (props: {
	current_round: number | null;
	set_current_round: (count: number | null) => void;
	total_rounds: number;
	size: string;
	onClose?: () => void;
}) => {
	const [isPlaying, setIsPlaying] = useState<boolean | null>(null);
	const classes = use_breath_circle_styles({ isPlaying: isPlaying });
	const [circleState, setCircleState] = useState<
		'3' | '2' | '1' | 'inhale' | 'exhale'
	>('3');
	const [counter, setCounter] = useState(5);
	const { current_round, set_current_round, total_rounds, size, onClose } =
		props;
	const dispatch = useDispatch<AppDispatch>();
	// useEffect(() => {
	// 	setIsPlaying(null);
	// }, [onClose]);

	useEffect(() => {
		if (circleState === '3' || circleState === '2' || circleState === '1') {
			const timeoutId = setTimeout(() => {
				if (circleState === '3') {
					setCircleState('2');
				} else if (circleState === '2') {
					setCircleState('1');
				} else if (circleState === '1') {
					onStart();
				}
			}, 1000);
			return () => clearTimeout(timeoutId);
		}
	}, [circleState]);
	const onStart = () => {
		setIsPlaying(true);
		set_current_round(1);
		setCounter(1);
		setCircleState('inhale');
	};
	useEffect(() => {
		if (counter === null || isPlaying === false) {
			return;
		}
		if (counter <= 6) {
			const intervalId = setInterval(() => {
				setCounter((prevCounter) => prevCounter + 1);
			}, 1000);

			return () => clearInterval(intervalId);
		} else {
			setTimeout(() => {
				if (!isPlaying) {
					return;
				}
				setCircleState((prev) =>
					prev === 'inhale' ? 'exhale' : 'inhale'
				);
				setCounter(1);
				if (circleState === 'exhale') {
					set_current_round(current_round + 1);
					if (current_round === total_rounds) {
						setIsPlaying(false);
						props?.onClose?.();
					}
				}
			}, 0);
		}
	}, [counter, setCircleState]);

	// useEffect(() => {
	// 	if (!isPlaying) return;
	// 	const intervalId = setInterval(() => {
	// 		setTimer((prevTimer) => {
	// 			if (prevTimer <= 1) {
	// 				clearInterval(intervalId);
	// 				setIsPlaying(false);
	// 				return 0;
	// 			}
	// 			return prevTimer - 1;
	// 		});
	// 	}, 1000);
	// 	return () => clearInterval(intervalId);
	// }, [isPlaying]);

	// const onStop = () => {
	// 	setIsPlaying(null);
	// 	set_current_round(null);
	// 	setCounter(null);
	// 	setCircleState(null);
	// 	const final_breath_count =
	// 		circleState === 'exhale' ? current_round : current_round - 1;
	// 	if (final_breath_count > 0) {
	// 		dispatch(post_breath_count(final_breath_count));
	// 	}
	// 	// if (onClose) {
	// 	// 	onClose();
	// 	// }
	// };
	const circle_size =
		size === 'large'
			? '290px'
			: props.size === 'medium'
			  ? '210px'
			  : '180px';
	const CenterText = (
		// <Fade in={true} timeout={100}>
		<Grid
			container
			item
			xs
			direction="column"
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 10000,
				width: 'auto',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				backgroundColor: CENTER_COLOR //'#8E9BB6',

				// pointerEvents: 'none'
			}}
		>
			<SageType
				sx={{
					color: get_cta_color(),
					textTransform: 'none',
					fontSize: props.size === 'large' ? '32px' : '18px',
					textAlign: 'center'
				}}
			>
				{circleState}
			</SageType>
		</Grid>
		// </Fade>
	);

	return (
		<Grid
			container
			item
			xs
			sx={{
				position: 'relative',
				marginTop: props.size === 'large' ? '32px' : '0px',
				height: circle_size,
				width: circle_size
			}}
		>
			<Grid
				sx={{
					alignItems: 'center',
					justifyContent: 'center',
					position: 'absolute',
					backgroundColor: OUTER_COLOR,
					display: 'flex',
					height: circle_size,
					width: circle_size,
					borderRadius: '50%'
				}}
			>
				{/* {CenterText} */}
			</Grid>
			<Grid
				className={
					circleState === 'exhale' ? classes.exhale : classes.inhale
				}
				sx={{
					backgroundColor: INNER_COLOR,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					width: circle_size,
					height: circle_size,
					borderRadius: '50%',
					animationPlayState: 'running'
				}}
			>
				{CenterText}
			</Grid>
		</Grid>
	);
};
