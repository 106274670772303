import { Grid } from '@mui/material';
import React from 'react';
import SageType from '../../typography/SageType';

interface CardHeaderProps {
  title: string;
  rightComponent?: React.ReactNode;
  onClick?: () => void;
}

const CardHeader: React.FC<CardHeaderProps> = ({ title, rightComponent, onClick }) => {
  return (
    <Grid
      container 
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      sx={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      <Grid container item xs>
        <SageType fontSize="14px">
        {title}
        </SageType>
      </Grid>
      {rightComponent && (
        <Grid container item xs >
          {rightComponent}
        </Grid>
      )}
    </Grid>
  );
};

export default CardHeader; 