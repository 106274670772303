import { Add, Remove } from '@mui/icons-material';
import { Grid, Modal, Skeleton, Tooltip, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_cta_color,
  get_sage_thought,
  getDarkGreen,
  LIGHT_GREEN,
  SubmitButton,
} from '../../../Util/Helper';
import {
  AppDispatch,
  GuidingQuestions,
  RootState,
  SageThought,
  Thought,
} from '../../../Util/Redux/Schema';

import { useState } from 'react';
import { update_user_reframed_thought } from '../../../Util/Redux/AsyncMiddleware';
import sageCircle from '../../../assets/images/logo/sageCircle.svg';
import sagethat from '../../../assets/images/logo/sagethat.png';
import CustomTextField from '../../../components/molecules/CustomTextField';
import ReleaseThought from './ReleaseThought';

export const Header = (props: { pageIdx: number }) => {
  return (
    <Grid container item justifyContent="space-between" alignItems="center">
      <img src={sageCircle} style={{ height: '48px', width: '44px', marginRight: '8px' }} />
      <img src={sagethat} style={{ height: '32px', width: '140px' }} />
      <Grid container item xs justifyContent="flex-end" paddingRight="24px" />
    </Grid>
  );
};
export const SageWorksheet = (props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  thought: Thought;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [pageIdx, setPageIdx] = useState(0);

  const sage_thought = useSelector((state: RootState) =>
    get_sage_thought(state.sageThoughts, props.thought.uuid),
  );
  const [reframed_thought, setReframedThought] = useState(
    sage_thought?.user_reframed_thought || '',
  );

  const onClick = () => {
    setPageIdx(pageIdx + 1);
  };
  const [openIdx, setOpenIdx] = useState(0);
  const [completed, setCompleted] = useState<boolean[]>([false, false, false, false]);
  const setGenerateJoyCompleted = (isCompleted: boolean) => {
    setCompleted(completed.map((c: boolean, idx: number) => (idx === 2 ? isCompleted : c)));
  };
  const setOpenIdxAndCompleted = (idx: number) => {
    setOpenIdx(idx);
    if (idx !== 0 && !completed[0]) {
      setCompleted(completed.map((c, idx) => (idx === 0 ? true : c)));
    }
    if (openIdx === 1 && idx !== 1 && !completed[1]) {
      setCompleted(completed.map((c, idx) => (idx === 1 ? true : c)));
    }
  };
  const onSubmitWorksheet = () => {
    props.setOpen(false);
    dispatch(update_user_reframed_thought(reframed_thought, sage_thought.uuid, props.thought.uuid));
  };
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      sx={{ zIndex: 2000, backdropFilter: 'blur(5px)' }}
    >
      <Grid
        container
        sx={{
          padding: '32px',
          width: '640px',
          height: '720px',
          borderRadius: '16px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        direction="column"
      >
        <Grid container item xs direction="column" justifyContent="center">
          <Header pageIdx={pageIdx} />

          {pageIdx === 0 && <ViewThought thought={props.thought.thought} />}
          <Grid
            container
            item
            xs
            padding="16px"
            paddingLeft={0}
            borderRadius="12px"
            justifyContent="center"
          >
            {pageIdx === 0 ? (
              <Grid container item direction="column">
                <Assess
                  empathy={sage_thought?.empathy}
                  guiding_questions={sage_thought?.guiding_questions?.slice(0, 2) || []}
                  open={true}
                  setOpen={() => setOpenIdxAndCompleted(1)}
                  completed={completed[1]}
                />
                <GenerateJoy
                  userReframedThought={reframed_thought}
                  setUserReframedThought={setReframedThought}
                  cosReframedThought={sage_thought?.reframed_thought || ''}
                  completed={completed[2]}
                  setCompleted={setGenerateJoyCompleted}
                  thoughtUuid={props.thought.uuid}
                />
              </Grid>
            ) : (
              <Embrace sageThought={sage_thought} onClose={onSubmitWorksheet} />
            )}

            <Grid container item justifyContent="center" marginTop="24px" xs>
              {pageIdx === 0 && <InternalSubmit onClick={onClick} disabled={!reframed_thought} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

const ViewThought = (props: { thought: string }) => {
  return (
    <Grid container item alignItems="center" margin="8px 0px">
      <Tooltip PopperProps={{ style: { zIndex: 5000 } }} title={props.thought}>
        <Typography
          sx={{
            fontSize: '14px',
            lineHeight: '20px',
            fontFamily: 'arial',
            fontWeight: 600,
            color: '#7F7D83',
            cursor: 'default',
            textDecoration: 'underline',
          }}
        >
          View your harsh thought
        </Typography>
      </Tooltip>
    </Grid>
  );
};

const BodyTypography = (props: { children: React.ReactElement | string }) => {
  return (
    <Typography
      sx={{
        fontSize: '14px',
        lineHeight: '20px',
        fontFamily: 'arial',
        fontWeight: 400,
        color: '#7F7D83',
        cursor: 'default',
      }}
    >
      {props.children}
    </Typography>
  );
};

const Assess = (props: {
  guiding_questions: GuidingQuestions[];
  empathy: string;
  open: boolean;
  setOpen: () => void;
  completed: boolean;
}) => {
  const patterns = props.guiding_questions;
  const EmptySkeleton = () => (
    <>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" style={{ marginTop: '16px' }} />
    </>
  );
  const AssessBody = () => (
    <Grid container direction="column">
      {patterns.map((type, idx) => {
        return (
          <Grid container item direction="row" alignItems="center" marginTop={idx && '8px'}>
            <Grid container item xs>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontFamily: 'arial',
                  fontWeight: 400,
                  color: '#7F7D83',
                  cursor: 'default',
                }}
              >
                💭 {type.question}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
  const body = (
    <Grid
      container
      item
      xs
      marginTop="12px"
      direction="column"
      sx={{
        backgroundColor: '#F2F5F9',
        height: '100px',
        border: '1px solid #EBEBEB',
      }}
      borderRadius="8px"
      padding="16px"
    >
      {props.guiding_questions.length > 0 ? <AssessBody /> : <EmptySkeleton />}
    </Grid>
  );
  return <Template title={props.empathy} body={body} />;
};

const Template = (props: { startIcon?: any; title: string; body: any }) => {
  return (
    <>
      <Grid container item xs width="100%" alignItems="center">
        {props.startIcon}
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '20px',
            fontFamily: 'arial',
            fontWeight: 600,
            // color: "#7F7D83",
            color: get_cta_color(),
            cursor: 'default',
          }}
        >
          {props.title}
        </Typography>
      </Grid>
      {props.body}
    </>
  );
};

const GenerateJoy = (props: {
  userReframedThought: string;
  setUserReframedThought: (userReframedThought: string) => void;
  cosReframedThought: string;
  completed: boolean;
  setCompleted: (completed: boolean) => void;
  thoughtUuid: string;
}) => {
  const EmptySkeleton = () => (
    <>
      <Grid container item xs width="100%" alignItems="center">
        <Grid container item xs marginBottom="24px">
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '20px',
              fontFamily: 'arial',
              fontWeight: 400,
              color: '#7F7D83',
              cursor: 'default',
            }}
          >
            Example:{' '}
          </Typography>
          <Skeleton animation="wave" style={{ width: '496px', marginLeft: '8px' }} />
          <Skeleton animation="wave" style={{ width: '564px' }} />
        </Grid>
      </Grid>
      <CustomTextField
        disabled
        placeholder="A more positive way to think about this is..."
        // value={props.userReframedThought}
        // setValue={props.setUserReframedThought}
        // setCompleted={props.setCompleted}
        backgroundColor="white"
      />
    </>
  );
  const body = (
    <Grid
      container
      item
      // xs
      marginTop="8px"
      direction="column"
      paddingBottom="8px"
      sx={{
        backgroundColor: '#F2F5F9',
        height: '210px',
        border: '1px solid #EBEBEB',
      }}
      borderRadius="8px"
      padding="16px"
    >
      {props.cosReframedThought ? (
        <>
          <Grid container item xs width="100%" alignItems="center">
            <Grid container item xs marginBottom="24px">
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontFamily: 'arial',
                  fontWeight: 400,
                  color: '#7F7D83',
                  cursor: 'default',
                  // marginBottom: "24px",
                }}
              >
                Example:{' '}
                {props.cosReframedThought ||
                  "I have my own unique preferences, and it's okay if they differ from others."}
              </Typography>
            </Grid>
          </Grid>
          <CustomTextField
            placeholder="A more positive way to think about this is..."
            value={props.userReframedThought}
            setValue={props.setUserReframedThought}
            setCompleted={props.setCompleted}
            backgroundColor="white"
          />
        </>
      ) : (
        <EmptySkeleton />
      )}
    </Grid>
  );
  return (
    <Grid container marginTop="24px">
      <Template title="Reframe your thought" body={body} />
    </Grid>
  );
};

const Embrace = (props: { sageThought: SageThought; onClose: () => void }) => {
  const [started, set_started] = useState(false);
  const [selected_duration, set_selected_duration] = useState(1);
  const body = (
    <Grid container item direction="column" alignItems="center" justifyContent="center">
      {/* <Grid container item xs direction="column"> */}
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          fontFamily: 'arial',

          color: '#858580',
          cursor: 'default',
        }}
      >
        <span
          style={{
            fontSize: '16px',
            lineHeight: '20px',
            fontFamily: 'arial',
            cursor: 'default',
          }}
        >
          {props.sageThought.healthy_suggestions} <br />
          <br />
          <span style={{ fontSize: '14px' }}>
            If you need more support, try using the mindful breathing tool. Staying present can help
            you move through any lingering discomfort.
          </span>
        </span>
        <br />
        <br />
        <br />
        <br />
        Whenever you're ready, take a deep breath and
        <b> release</b> this harsh thought.
      </Typography>
      <ReleaseThought onClose={props.onClose} />
      {/* </Grid> */}
    </Grid>
  );
  return <Template title="Congratulations on befriending your mind!" body={body} />;
};

const InternalSubmit = (props: {
  onClick: () => void;
  disabled: boolean;
  cancel?: boolean;
  title?: string;
}) => {
  const is_breathe = props.title === 'breathe';
  return (
    <SubmitButton
      text={is_breathe ? 'breathe' : props.cancel ? 'Skip' : 'Submit'}
      onClick={props.onClick}
      disabled={props.disabled}
      width="276px"
      height="42px"
      marginTop="8px"
      textTransform="none"
      secondary={props.cancel}
      backgroundColor={props.cancel ? 'white' : is_breathe ? LIGHT_GREEN : get_cta_color()}
      textColor={is_breathe ? getDarkGreen() : props.cancel ? '#2f2f2f' : 'white'}
      // cursor="pointer"
      // sx={{
      // 	'&:hover': {
      // 		backgroundColor: props.cancel ? 'white' : get_cta_color(),
      // 		textDecoration: props.cancel ? 'underline' : undefined
      // 	},
      // 	':disabled': {
      // 		backgroundColor: props.cancel ? 'white' : '#E6E6E6'
      // 	},
      // }}
    />
  );
};

export const BreathCountSelector = () => {
  const [breaths, setBreaths] = useState(5);
  return (
    <Grid
      container
      item
      alignItems="center"
      width="276px"
      height="64px"
      marginBottom="4px"
      borderRadius="12px"
    >
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        onClick={() => setBreaths(breaths > 1 ? breaths - 1 : breaths)}
        sx={{
          width: '40px',
          height: '40px',
          border: '1px solid #F1F1F1',
          borderRadius: '12px',
          cursor: breaths > 1 ? 'cursor' : 'not-allowed',
        }}
      >
        <Remove
          sx={{
            width: '20px',
            height: '20px',
            color: breaths > 1 ? get_cta_color() : '#DCDCDE',
          }}
        />
      </Grid>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{
          marginLeft: '24px',
          marginRight: '24px',
          width: '148px',
          height: '48px',
          borderRadius: '12px',
          border: '1px solid #F1F1F1',
        }}
      >
        <input
          value={breaths}
          onChange={(e) => setBreaths(parseInt(e.target.value))}
          style={{
            width: '100px',
            fontSize: '24px',
            lineHeight: '28px',
            fontWeight: 500,
            textAlign: 'center',
            border: 'none',
          }}
        ></input>
      </Grid>
      <Grid
        container
        item
        onClick={() => setBreaths(breaths + 1)}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: '40px',
          height: '40px',
          border: '1px solid #F1F1F1',
          borderRadius: '12px',
          cursor: 'cursor',
        }}
      >
        <Add
          sx={{
            width: '20px',
            height: '20px',
            color: get_cta_color(),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SageWorksheet;
