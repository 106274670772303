import { Grid } from '@mui/material';
import TimeSelectionButtons from './components/TimeSelectionButtons';

export default function BreathDurationConfig(props: {
	selected_duration: number;
	set_selected_duration: (duration: number) => void;
	set_started: () => void;
}) {
	return (
		<Grid container item xs justifyContent="center" alignItems="center">
			<Grid container item xs={8} direction="column">
				<TimeSelectionButtons
					selected_duration={props.selected_duration}
					set_selected_duration={props.set_selected_duration}
				/>
			</Grid>
		</Grid>
	);
}
