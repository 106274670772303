import { Grid } from '@mui/material';
import MindfulMoments from '../quest/mindfulness/MindfulMoments';
import QuestContent from '../quest/stats/daily/QuestContent';

const RightSidebar = () => {
	return (
		<Grid container item xs marginLeft="24px" >
            <Grid container item xs >
			<Grid container item xs direction="column">
			<Grid container item>
			<QuestContent date_range="Day" />
				</Grid>
				<Grid container item  paddingTop="24px">
				<MindfulMoments />
				</Grid>
		
			</Grid>
		</Grid></Grid>
	);
};

export default RightSidebar; 